import { AfterViewInit, Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../shared/base.component";
import { ApiService } from "src/app/services";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { ModalService } from "src/app/shared/modal.service";
import { ColumnApi, GridOptions } from "ag-grid-community";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { FfpPlanInstanceStatusEnum } from "src/app/shared/enums/plan-enums";
import { IUserProfile } from "src/app/npr-request.model";
import { getPlanRegionApprovalsColumnDefinition } from "../plans/ag-grid-column-definition";
import { FfpPlanRejectData } from "../../generated-models/FfpPlanRejectData";
import { FfpPlanFile } from "../../generated-models/FfpPlanFile";
import { FfpPlanInstance } from "../../generated-models/FfpPlanInstance";
import { FfpPlanInstanceOverallApproval } from "../../generated-models/FfpPlanInstanceOverallApproval";
import { FfpPlanInstanceApproval } from "../../generated-models/FfpPlanInstanceApproval";
import { Project } from "../../generated-models/Project";

@Component({
  templateUrl: "./ffp-plan-review.component.html",
  styleUrls: ["../../styles.scss", "./ffp.scss"],
})
export class FfpPlanReviewComponent extends BaseComponent implements AfterViewInit, OnInit {
  gridOptions: GridOptions;
  gridColumnApi: ColumnApi;
  editor: monaco.editor.IStandaloneDiffEditor;
  ffpPlanInstanceApprovalsGridOptions: GridOptions;
  ffpPlanInstanceApprovalsColumnApi: ColumnApi;
  ffpPlanInstanceApprovalsRowData: FfpPlanInstanceApproval[] = [];

  serviceTreeId: string;
  planType: string;
  projectOid: string;
  version: number;
  ffpPlanInstance: FfpPlanInstance;
  latestVersion: number = null;
  ffpPlanLatestVersionReviewLink: string;
  message: string;
  canApprove = false;
  canReject = false;
  showRejectComment = false;
  userProfile: IUserProfile;
  project: Project;

  currentFfpPlanFile: FfpPlanFile;
  lastApprovedFfpPlanFile: FfpPlanFile;

  constructor(
    private readonly route: ActivatedRoute,
    private apiService: ApiService,
    protected modalService: ModalService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService,
    private title: Title
  ) {
    super();
  }

  async ngOnInit() {
    this.ffpPlanInstanceApprovalsGridOptions = extendDefaultOptions({
      columnDefs: getPlanRegionApprovalsColumnDefinition(), // TODO: should set value after we obtain the approver list
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
      suppressPaginationPanel: true,
      sideBar: null,
    });

    this.serviceTreeId = this.route.snapshot.params["serviceTreeId"];
    this.projectOid = this.route.snapshot.params["projectOid"];
    this.version = this.route.snapshot.params["version"];
    this.title.setTitle(`${this.route.snapshot.data.title} for ${this.serviceTreeId} on ${this.projectOid} v${this.version}`);
    this.project = await this.apiService.getProject(this.projectOid).toPromise();

    this.loadingService.setLoading(true);
    this.userProfile = await this.apiService.getUserProfile();
    await this.loadFfpPlan();
    await this.loadFfpPlanRegionApprovals();
    this.loadingService.setLoading(false);

    setTimeout(() => {
      this.gridColumnApi.autoSizeAllColumns();
      this.ffpPlanInstanceApprovalsColumnApi.autoSizeAllColumns();
    }, 100);
  }

  onGridReady(params: GridOptions) {
    this.gridColumnApi = params.columnApi;
  }

  onPlanRegionApprovalsGridReady(params: GridOptions) {
    this.ffpPlanInstanceApprovalsColumnApi = params.columnApi;
  }

  async loadFfpPlan() {
    await this.apiService
      .getFfpPlanInstance(this.serviceTreeId, this.projectOid, this.version)
      .toPromise()
      .then((response) => {
        this.ffpPlanInstance = response;
        if (this.ffpPlanInstance.Status === FfpPlanInstanceStatusEnum.Approvable) {
          this.canApprove = this.userProfile?.IsApprover || this.userProfile?.IsAdmin;
          this.canReject = true;
        }
      })
      .catch((e) => {
        this.notificationService.error(e);
      });

    const latestVersionFfpPlanInstance = await this.apiService
      .getFfpPlanInstanceLatestVersion(this.serviceTreeId, this.projectOid)
      .toPromise();
    this.latestVersion = latestVersionFfpPlanInstance.Version;
    if (this.latestVersion != null) {
      this.ffpPlanLatestVersionReviewLink = `/quota/ffp/instances/services/${this.serviceTreeId}/projects/${this.projectOid}/versions/${this.latestVersion}/review`
    }
  }

  async loadFfpPlanRegionApprovals() {
    return await this.apiService
      .getFfpInstancePlanApprovals(this.serviceTreeId, this.projectOid, this.version)
      .toPromise()
      .then((response) => {
        this.ffpPlanInstanceApprovalsRowData = response;
      })
      .catch((e) => {
        this.notificationService.error(e);
      });
  }

  async showApproveFfpPlanDialog() {
    this.approveFfpPlan();
  }

  approveFfpPlan() {
    const ffpPlanInstanceOverallApproval: FfpPlanInstanceOverallApproval = {
      ApprovedVersion: this.ffpPlanInstance.Version,
    } as FfpPlanInstanceOverallApproval;

    this.loadingService.setLoading(true);

    this.apiService.approveFfpPlanInstance(this.ffpPlanInstance.ServiceTreeId, this.ffpPlanInstance.ProjectOid, ffpPlanInstanceOverallApproval).subscribe(
      (response) => {
        if (response) {
          this.message = response.Message;
        } else {
          this.message = `Ffp Plan have been approved successfully.`;
        }

        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (err) => {
        this.message = `Failed to approve Ffp plan. Server response: ${err}`;
        this.notificationService.error(this.message);
        this.loadingService.setLoading(false);
      },
      async () => {
        this.loadingService.setLoading(true);
        await this.loadFfpPlan();
        await this.loadFfpPlanRegionApprovals();
        this.loadingService.setLoading(false);
      }
    );
  }

  async showRejectFfpPlanDialog() {
    await this.rejectFfpPlan();
  }

  rejectFfpPlan() {
    const ffpPlanRejectData: FfpPlanRejectData = {
      Version: this.ffpPlanInstance.Version,
    } as FfpPlanRejectData;

    this.loadingService.setLoading(true);

    this.apiService.rejectFfpPlanInstance(this.ffpPlanInstance.ServiceTreeId, this.ffpPlanInstance.ProjectOid, ffpPlanRejectData).subscribe(
      () => {
        this.message = `Ffp Plan have been rejected.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (err) => {
        this.message = `Failed to reject ffp plan. Server response: ${err}`;
        this.notificationService.error(this.message);
        this.loadingService.setLoading(false);
      },
      async () => {
        this.loadingService.setLoading(true);
        await this.loadFfpPlan();
        await this.loadFfpPlanRegionApprovals();
        this.loadingService.setLoading(false);
      }
    );
  }

  showStatus(ffpPlanInstance: FfpPlanInstance) {
    return ffpPlanInstance.Status;
  }

  showSubmit(ffpPlanInstance: FfpPlanInstance) {
    if (ffpPlanInstance.Submitter && ffpPlanInstance.SubmitTime) {
      return `by ${ffpPlanInstance.Submitter} at ${PreprocessDateTime(ffpPlanInstance.SubmitTime)}`;
    } else {
      return `N/A`;
    }
  }

  async onEditorReady(editor: monaco.editor.IStandaloneDiffEditor) {
    await this.getFfpPlanFiles();
    this.editor = editor;
    var originalModel = monaco.editor.createModel(this.lastApprovedFfpPlanFile?.Content, "application/json");
    var modifiedModel = monaco.editor.createModel(this.currentFfpPlanFile?.Content, "application/json");

    this.editor.setModel({
      original: originalModel,
      modified: modifiedModel,
    });

    // delay 1sec for formatting the content
    setTimeout(() => {
      this.editor
        .getModifiedEditor()
        .getAction("editor.action.formatDocument")
        .run()
        .then(() => {
          this.editor.updateOptions({ readOnly: true });
        });
    }, 1000);
  }

  async getFfpPlanFiles() {
    this.loadingService.setLoading(true);
    this.planType = (await this.apiService.getProject(this.projectOid).toPromise()).PlanType;
    await this.apiService.getFfpPlanFiles(this.serviceTreeId, this.planType, this.version)
      .toPromise()
      .then((response) => {
        response.forEach((plan) => {
          if (plan.FileName === "default.json") {
            this.currentFfpPlanFile = plan;
          }
        });
      })
      .catch((e: unknown) => {
        this.loadingService.setLoading(false);
        this.notificationService.error(e as string);
      });

    await this.apiService.getLatestApprovedFfpPlanFiles(this.serviceTreeId, this.projectOid)
      .toPromise()
      .then((response) => {
        if (response != null) {
          response.forEach((plan) => {
            if (plan.FileName === "default.json") {
              this.lastApprovedFfpPlanFile = plan;
            }
          });
        }
        this.loadingService.setLoading(false);
      })
      .catch((e: unknown) => {
        this.loadingService.setLoading(false);
        this.notificationService.error(e as string);
      });
  }
}
