import { Component, Input } from "@angular/core";
import { AgRendererComponent } from "ag-grid-angular";


@Component({
  template: `{{ status }}
    <span *ngIf="status === 'Pending' && dialogHandler">
        &nbsp;(see
        <a href="javascript:void(0)"  
            (click)="openDialog()">
                who can approve
        </a>
        )
    </span>`,
})
export class WhoCanApproveRendererComponent implements AgRendererComponent {
  public params: any;
  public status: string;
  public type: string;

  @Input() dialogHandler!: (resource: string) => void;
  
  agInit(params: any): void {
    this.params = params;
    this.status = params.value || '';
    this.type = params.data?.Resource || '';
    if (params.dialogHandler) {
      this.dialogHandler = params.dialogHandler;
    }
  }
  

  refresh(): boolean {
    return false;
  }

  openDialog() {
    if (this.dialogHandler) {
      this.dialogHandler(this.type);
    }
  }
}