import { AfterViewInit, Component, OnInit } from "@angular/core";
import { BaseComponent } from "../../shared/base.component";
import { ApiService } from "src/app/services";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { Title } from "@angular/platform-browser";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { ModalService } from "src/app/shared/modal.service";
import { PlanRegionOverallApproval } from "src/app/generated-models/PlanRegionOverallApproval";
import { ColumnApi, GridOptions } from "ag-grid-community";
import { extendDefaultOptions } from "src/app/utility/gridHelper";
import { CapacityOrderColumnDefinition, getPlanRegionApprovalsColumnDefinition, PlansDiffColumnDefinition } from "./ag-grid-column-definition";
import { PlanRegionDiff } from "src/app/generated-models/PlanRegionDiff";
import { PlanRegionStatusEnum } from "src/app/shared/enums/plan-enums";
import { PlanRejectData } from "src/app/generated-models/PlanRejectData";
import { IUserProfile } from "src/app/npr-request.model";
import { CapacityOrder } from "src/app/generated-models/CapacityOrder";
import { PlanRegionApproval } from "../../generated-models/PlanRegionApproval";
import { Region } from "src/app/generated-models";
import { Alternative } from "../../generated-models/Alternative";
import { UpdateAlternative } from "../../generated-models/UpdateAlternative";
import { HttpErrorResponse } from "@angular/common/http";

interface PlanContent {
  serviceTreeId: string;
  email: string;
  version: string;
  stages: Record<string, any>;
}

@Component({
  templateUrl: "./plan-review.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})

export class PlanReviewComponent extends BaseComponent implements AfterViewInit, OnInit {
  gridOptions: GridOptions;
  gridColumnApi: ColumnApi;
  capacityOrderGridOptions: GridOptions;
  capacityOrderGridColumnApi: ColumnApi;
  planRegionApprovalsGridOptions: GridOptions;
  planRegionApprovalsColumnApi: ColumnApi;
  planDiffRowData: PlanRegionDiff[] = [];
  capacityOrderRowData: CapacityOrder[] = [];
  planRegionApprovalsRowData: PlanRegionApproval[] = [];
  region: Region;

  serviceTreeId: string;
  regionName: string;
  version: number;
  stage: string;
  planRegion: PlanRegion;
  latestVersion: number = null;
  planDetailLink: string;
  planLatestVersionReviewLink: string;
  message: string;
  contactEmail: string;
  canApprove = false;
  canReject = false;
  showRejectComment = false;
  userProfile: IUserProfile;
  isAdmin = false;
  isGCTApprover = false;
  readonly serviceType = "VM";
  alternatives: Alternative[];
  alternativeMap: { [key: string]: string[] } = {};
  alternativeAssignedMap: { [key: string]: string } = {};
  alternativeOriginals: string[] = [];
  isGAStage = false;
  fulfillmentStarted = false;
  

  constructor(
    private readonly route: ActivatedRoute,
    private apiService: ApiService,
    protected modalService: ModalService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService,
    private title: Title
  ) {
    super();
  }

  async ngOnInit() {
    this.gridOptions = extendDefaultOptions({
      columnDefs: PlansDiffColumnDefinition,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
      suppressPaginationPanel: true,
      sideBar: null,
    });

    this.capacityOrderGridOptions = extendDefaultOptions({
      columnDefs: CapacityOrderColumnDefinition,
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
      suppressPaginationPanel: true,
      sideBar: null,
    });

    this.planRegionApprovalsGridOptions = extendDefaultOptions({
      columnDefs: getPlanRegionApprovalsColumnDefinition(this.ShowWhoCanApproveDialog.bind(this)),
      enableRangeSelection: false,
      rowSelection: "single",
      animateRows: true,
      suppressPaginationPanel: true,
      sideBar: null,
    });

    this.serviceTreeId = this.route.snapshot.params["serviceTreeId"];
    this.regionName = this.route.snapshot.params["region"];
    this.stage = this.route.snapshot.params["stage"];
    this.isGAStage = this.stage == "GA";
    if (this.route.snapshot.params.hasOwnProperty("version")) {
      this.version = this.route.snapshot.params["version"];
    }
    else {
      try {
        const latestVersionPlanRegion = await this.apiService
          .getPlanRegionLatestVersion(this.serviceTreeId, this.regionName, this.stage)
          .toPromise();
        this.version = latestVersionPlanRegion.Version;
      } catch (e) {
        this.notificationService.error(e);
      }
    }
    this.title.setTitle(`${this.route.snapshot.data.title} for ${this.serviceTreeId} on ${this.regionName} v${this.version}`);
    this.planDetailLink = `/quota/plans/services/${this.serviceTreeId}/regions/${this.regionName}/versions/${this.version}/stages/${this.stage}/detail`

    this.loadingService.setLoading(true);
    this.userProfile = await this.apiService.getUserProfile();
    if (this.userProfile?.IsAdmin) {
      this.isAdmin = true;
    }
    if (this.userProfile?.IsGCTApprover) {
      this.isGCTApprover = true;
    }

    await this.loadPlan();
    await this.loadPlanDiff();
    await this.loadPlanRelatedCapacityOrders();
    await this.loadPlanRegionApprovals();
    await this.loadPlanRegionStatus();
    await this.loadAlternatives();
    this.loadingService.setLoading(false);

    setTimeout(() => {
      this.gridColumnApi.autoSizeAllColumns();
      this.capacityOrderGridColumnApi.autoSizeAllColumns();
      if (this.planRegionApprovalsColumnApi) {
        this.planRegionApprovalsColumnApi.autoSizeAllColumns();
      }
    }, 100);
  }

  onGridReady(params: GridOptions) {
    this.gridColumnApi = params.columnApi;
  }

  onCapacityOrderGridReady(params: GridOptions) {
    this.capacityOrderGridColumnApi = params.columnApi;
  }

  onPlanRegionApprovalsGridReady(params: GridOptions) {
    this.planRegionApprovalsColumnApi = params.columnApi;
  }

  async loadPlan() {
    try {
      const planRegion = await this.apiService
        .getPlanRegion(this.serviceTreeId, this.regionName, this.version, this.stage)
        .toPromise();
      this.planRegion = planRegion;
      if (this.planRegion.Status === PlanRegionStatusEnum.Approvable) {
        this.canApprove = this.userProfile?.IsApprover;
        this.canReject = this.userProfile?.IsAdmin;
      }
      if (this.planRegion.Status == PlanRegionStatusEnum.Rejected && this.planRegion.RejectComment) {
        this.showRejectComment = true;
      }

      const planRegionDetail = await this.apiService
        .getPlanRegionDetail(this.serviceTreeId, this.regionName, this.version, this.stage)
        .toPromise();
      const jsonObject: PlanContent = JSON.parse(planRegionDetail.PlanFile?.Content);
      if (jsonObject) {
        this.contactEmail = jsonObject.email;
      }

      const latestVersionPlanRegion = await this.apiService
        .getPlanRegionLatestVersion(this.serviceTreeId, this.regionName, this.stage)
        .toPromise();

      var regions = await this.apiService
        .getRegionList()
        .toPromise();
      this.region = regions.find(r => r.RegionName == this.regionName);

      this.latestVersion = latestVersionPlanRegion.Version;
      if (this.latestVersion != null) {
        this.planLatestVersionReviewLink = `/quota/plans/services/${this.serviceTreeId}/regions/${this.regionName}/versions/${this.latestVersion}/stages/${this.stage}/review`
      }
    }
    catch (e) {
      this.notificationService.error(e);
    }
  }

  async loadPlanDiff() {
    return await this.apiService
      .getRegionalPlanDiff(this.serviceTreeId, this.regionName, this.version, this.stage)
      .toPromise()
      .then((response) => {
        this.planDiffRowData = response;
      })
      .catch((e) => {
        this.notificationService.error(e);
      });
  }

  async loadPlanRelatedCapacityOrders() {
    return await this.apiService
      .getRegionalPlanRelatedCapacityOrders(this.serviceTreeId, this.regionName, this.version, this.stage)
      .toPromise()
      .then((response) => {
        this.capacityOrderRowData = response;
      })
      .catch((e) => {
        this.notificationService.error(e);
      });
  }

  async loadPlanRegionApprovals() {
    return await this.apiService
      .getRegionalPlanApprovals(this.serviceTreeId, this.regionName, this.version, this.stage)
      .toPromise()
      .then((response) => {
        this.planRegionApprovalsRowData = response;
      })
      .catch((e) => {
        this.notificationService.error(e);
      });
  }

  async showApprovePlanDialog() {
    this.approvePlan();
  }

  ShowWhoCanApproveDialog(type: string) {
    this.modalService.resourceApproversModal(type, this.regionName);
  }
  
  approvePlan() {
    const planRegionOverallApproval: PlanRegionOverallApproval = {
      ApprovedVersion: this.planRegion.Version,
      Stage: this.planRegion.Stage,
    } as PlanRegionOverallApproval;

    this.loadingService.setLoading(true);

    this.apiService.approvePlanRegion(this.planRegion.ServiceTreeId, this.planRegion.Region, planRegionOverallApproval).subscribe(
      (response) => {
        if (response) {
          this.message = response.Message;
        } else {
          this.message = `Plan have been approved successfully.`;
        }

        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (err) => {
        this.message = `Failed to approve plan. Server response: ${err}`;
        this.notificationService.error(this.message);
        this.loadingService.setLoading(false);
      },
      async () => {
        this.loadingService.setLoading(true);
        await this.loadPlan();
        await this.loadPlanDiff();
        await this.loadPlanRegionApprovals();
        this.loadingService.setLoading(false);
      }
    );
  }

  async showRejectPlanDialog() {
    const comment = await this.modalService.planRejectModal();
    await this.rejectPlan(comment);
  }

  rejectPlan(comment: string) {
    const planRejectData: PlanRejectData = {
      Version: this.planRegion.Version,
      Stage: this.planRegion.Stage,
      Comment: comment,
    } as PlanRejectData;

    this.loadingService.setLoading(true);

    this.apiService.rejectPlanRegion(this.planRegion.ServiceTreeId, this.planRegion.Region, planRejectData).subscribe(
      () => {
        this.message = `Plan have been rejected.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (err) => {
        this.message = `Failed to reject plan. Server response: ${err}`;
        this.notificationService.error(this.message);
        this.loadingService.setLoading(false);
      },
      async () => {
        this.loadingService.setLoading(true);
        await this.loadPlan();
        await this.loadPlanDiff();
        await this.loadPlanRegionApprovals();
        this.loadingService.setLoading(false);
      }
    );
  }

  showStatus(planRegion: PlanRegion) {
    if (planRegion.Status === PlanRegionStatusEnum.Approved) {
      return `Approved by ${planRegion.ApprovedBy} at ${PreprocessDateTime(planRegion.ApprovedTime)}`;
    } else if (planRegion.Status === PlanRegionStatusEnum.Rejected) {
      return `Rejected by ${planRegion.RejectedBy} at ${PreprocessDateTime(planRegion.RejectedTime)}`;
    } else {
      return planRegion.Status;
    }
  }

  showSubmit(planRegion: PlanRegion) {
    if (planRegion.Submitter && planRegion.SubmitTime) {
      return `by ${planRegion.Submitter} at ${PreprocessDateTime(planRegion.SubmitTime)}`;
    } else {
      return `N/A`;
    }
  }

  async loadPlanRegionStatus() {
    return await this.apiService.getPlanRegionStatus(this.regionName, this.serviceTreeId, this.stage)
      .toPromise()
      .then((response) => {
        this.fulfillmentStarted = response.SubscriptionCompleted > 0;
      }).
      catch((e) => {
        this.notificationService.error(e);
      });
  }

  async loadAlternatives() {
    return await this.apiService
      .getAlternatives(this.serviceTreeId, this.regionName, this.version)
      .toPromise()
      .then((response) => {
        this.alternatives = response;          ;
        this.alternatives.forEach(item => {
          this.alternativeMap[item.Original] = [item.Original, ...item.Alternatives.filter(i => i != item.Original)];
          this.alternativeAssignedMap[item.Original] = item.Assigned ?? item.Original;
          this.alternativeOriginals.push(item.Original);
        });
      }).
      catch((e) => {
        this.notificationService.error(e);
      });
  }

  UpdateAlternativeValue(original: string, requireSkuAvailableInCrp: boolean = true) {
    const updateAlternative: UpdateAlternative = {
      Type: this.serviceType,
      Original: original,
      Assigned: this.alternativeAssignedMap[original],
      RequireSkuAvailableInCrp: requireSkuAvailableInCrp
    } as UpdateAlternative;

    this.UpdateAlternativeInternal(updateAlternative);
  }

  UpdateAlternativeInternal(updateAlternative: UpdateAlternative) {
    this.apiService
      .updateAlternative(this.serviceTreeId, this.regionName, this.version, updateAlternative)
      .subscribe(
        () => {
          this.notificationService.info(`the alternative of ${updateAlternative.Original} has been assigned ${updateAlternative.Assigned} successfully`);
        },
        (error: HttpErrorResponse) => {
          this.modalService.planAlternativeModalComponent(error).then(
            (requireSkuAvailableInCrp) => {
              if (requireSkuAvailableInCrp == false) {
                this.UpdateAlternativeValue(updateAlternative.Original, requireSkuAvailableInCrp);
              }
            }
          );
        }
      );
  }
}
