<div role="dialog" aria-modal="true">
  <div class="modal-header">
    <h2 class="modal-title h4">Alternative Update Results</h2>
  </div>
  <div class="modal-body">
      <div>
         <p>{{errorMessage}}</p>
      </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="continue()" [hidden]="requireSkuAvailableInCrp">Proceed</button>
    <button type="button" class="btn btn-warning" (click)="cancel()">Close</button>
  </div>
</div>
