<div class="modal-header">
  <h4 class="modal-title"> {{ approvalType }} approvers </h4>
</div>
<a class="modal-header-close" href="javascript:void(0)" (click)="activeModal.close({})" container="body" aria-label="Close">
  <span class="fa fa-times fa-lg"></span>
</a>
<div class="modal-body" style="max-height: 240px; overflow-y: auto; width: 100%">
  <ul style="list-style-type: none; padding: 0; margin: 0;">
    <li *ngFor="let approver of approvers">
      {{ approver }};
    </li>
  </ul>   
</div>