import { Component, OnInit, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/services";

@Component({
  templateUrl: "./resource-approvers-modal.component.html",
  styleUrls: ["../../styles.scss"],
})
export class ResourceApproversModalComponent implements OnInit {
  @Input() approvalType: string;
  @Input() region: string;

  approvers: string[] = [];
  message = "";

  constructor(
    public activeModal: NgbActiveModal,
    protected apiService: ApiService,
    protected notificationService: ToastrService) {}

  ngOnInit() {
    if (this.approvalType && this.region) {
        this.apiService.getApproversByTypeAndRegion(this.approvalType, this.region).subscribe(
          (res) => {
            this.approvers = res
          },
          (err) => {
            this.message = err.message;
            this.notificationService.error(this.message);
          }
        );
    }
  }
}
