import { Component, Input, OnInit, Output } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { HttpErrorResponse } from "@angular/common/http";
import { PlanValidationErrorCode } from "../../../../npr-request.model";

@Component({
  templateUrl: "./plan-alternative-modal.component.html",
  styleUrls: ["../../../../styles.scss"],
})
export class PlanAlternativeModalComponent implements OnInit {
  @Input() httpErrorResponse: HttpErrorResponse;
  @Output() errorMessage: string = "";
  requireSkuAvailableInCrp = true;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
    if (this.httpErrorResponse?.error?.Code == PlanValidationErrorCode.PlanValidationCrpSkuNotAvailable) {
      this.requireSkuAvailableInCrp = false;
    }
    this.errorMessage = this.httpErrorResponse?.error?.ErrorMessage;
    if (this.errorMessage == null || this.errorMessage.length == 0) {
      this.errorMessage = "Update alternative failed with internal error, please contact admin.";
    }
  }

  async continue() {
    this.activeModal.close(false);
  }

  cancel() {
    this.activeModal.close(true);
  }
}
