import { Component, OnInit } from "@angular/core";
import { ColDef, ColGroupDef, ColumnApi, ExcelExportParams, GridApi, GridOptions } from "ag-grid-community";
import { forkJoin } from "rxjs";
import { SubscriptionSupportInfoRDFE } from "src/app/generated-models/SubscriptionSupportInfoRDFE";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { SubscriptionSupportInfo } from "src/app/generated-models/SubscriptionSupportInfo";
import { BatchSku } from "src/app/generated-models/BatchSku";
import { ArmResourceType, AZ, HdiSku, Region, VmSku } from "../../generated-models";
import { IRequestRegion, ISqlTypeListResponse, ResourceType, allowedCommonRegionStatuses } from "../../npr-request.model";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { BaseComponent } from "../../shared/base.component";
import { NprConfiguration } from "../../app.configuration";
import { extendDefaultOptions } from "../../utility/gridHelper";
import { CustomTooltipComponent } from "../../shared/auxillary-components/custom-tooltip.component";
import { ServiceTypeRendererComponent } from "../../shared/az-mapping-modal/service-type-renderer.component";
import { RegionSupportInfo } from "../../generated-models/RegionSupportInfo";
import { SubscriptionSupportInfoARM } from "../../generated-models/SubscriptionSupportInfoARM";
import { SubscriptionRegionSupportInfo } from "../../generated-models/SubscriptionRegionSupportInfo";
import { ModalService } from "../../shared/modal.service";
import { VmDisk } from "../../generated-models/VmDisk";
import { VmSkuExt } from "./VmSkuExt";
import { ArmResourceTypeExt } from "./ArmResourceTypeExt";


@Component({
  templateUrl: "./region-information.component.html",
  styleUrls: ["../../styles.scss", "./region-information.scss"],
})
export class SupportInformationComponent extends BaseComponent implements OnInit {
  private NoRestriction = "NoRestriction";
  private NotAvailable = "NotAvailable";
  subscriptionPrompt = "Enter a subscription to view";
  domLayout = "autoHeight";
  guidRegExpression = NprConfiguration.guidRegExpression;
  regionList: Region[] = [];
  vmSkus: VmSku[] = [];
  hdiSkus: HdiSku[] = [];
  batchSkus: BatchSku[] = [];
  armResourceTypes: ArmResourceType[] = [];
  regionsSupportVmDisk: string[] = [];
  sqlTypeList: ISqlTypeListResponse[] = [];
  region: IRequestRegion;
  subscriptionId: string;
  allRegion: IRequestRegion[] = [];
  private regionSupportInfo: RegionSupportInfo[];
  subscriptionSupportInfo: SubscriptionSupportInfo;
  subscriptionSupportInfoARM: SubscriptionSupportInfoARM;
  subscriptionSupportInfoRDFE: SubscriptionSupportInfoRDFE;
  supportedAfecs: string[];
  currentRegion: RegionSupportInfo;
  currentVmSkus: VmSkuExt[];
  currentArmResourceTypes: ArmResourceTypeExt[];
  currentHdiSkus: HdiSku[];
  currentBatchSkus: BatchSku[];
  currentVmDiskRegionName: string;
  currentVmDisks: VmDisk[];
  currentRegionAccess: string;
  currentAzAccess: string;
  deploymentType = "ARM";
  deploymentTypes: string[] = ["ARM", "RDFE"];
  serviceType: string = ResourceType.Compute;
  serviceTypes: string[] = [ResourceType.Compute, ResourceType.Storage, ResourceType.Hdi, ResourceType.Batch, ResourceType.ArmResourceType, ResourceType.VmDisk];
  currentAzs: AZ[] = [];
  subscriptionRegionSupportInfo: SubscriptionRegionSupportInfo;
  subscriptionVmDiskSupportInfo: VmDisk[];
  clouds: string[] = [];
  cloud = "";
  previousRegion = "";
  previousSub = "";
  resourceType = ResourceType;
  tooltipShowDelay = 0;
  tooltipHideDelay = 2000;
  isRegionAccess = false;
  IsRegionAccessible = false;
  vmDiskRowDataTemplate = [
    {
      DiskQuotaTypeName: "Premium SSD V2 managed Disk Capacity",
      DiskQuotaType: "PremiumV2DiskSizeInGB",
      AutoApprovalDiskQuota: 25000,
      Location: "",
    },
    {
      DiskQuotaTypeName: "Premium SSD V2 managed Disks",
      DiskQuotaType: "PremiumV2DiskCount",
      AutoApprovalDiskQuota: 2000,
      Location: "",
    },
    {
      DiskQuotaTypeName: "Ultra Disk Capacity",
      DiskQuotaType: "UltraSSDDiskSizeInGB",
      AutoApprovalDiskQuota: 50000,
      Location: "",
    },
    {
      DiskQuotaTypeName: "Ultra Disks",
      DiskQuotaType: "UltraSSDDiskCount",
      AutoApprovalDiskQuota: 2000,
      Location: "",
    }
  ]

  gridOptions: GridOptions;
  hdiGridOptions: GridOptions;
  batchGridOptions: GridOptions;
  armResourceTypeGridOptions: GridOptions;
  vmDiskGridOptions: GridOptions;
  serviceNotReady = "";
  private columnDef: ColDef[] = [
    { headerName: "CRP VM SKU Family", field: "CrpVmSku" },
    { headerName: "Azure Portal Display Name", field: "PortalVmSku" },
    { headerName: "CAS Offer Family", field: "CasVmSku" },
    { headerName: "CRP VM SKU Family ID", field: "CrpVmSkuId" },
    { headerName: "Restricted", field: "Restricted" },
    { headerName: "Restricted - Zonal", field: "RestrictedZonal" },
    { headerName: "Quota", field: "Quota", filter: 'agNumberColumnFilter' },
    { headerName: "Usage", field: "Usage", filter: 'agNumberColumnFilter' },
    { headerName: "Restrictions by Customer Segment", field: "RestrictionsByCustomerSegement", maxWidth: 800, minWidth: 300, tooltipField: "RestrictionsByCustomerSegement" },
    { headerName: "Zonal Restrictions by Customer Segment", field: "RestrictionsByCustomerSegementZonal", maxWidth: 800, minWidth: 300, tooltipField: "RestrictionsByCustomerSegementZonal" },
  ];
  private hdiColumnDef: ColDef[] = [
    { headerName: "CRP VM SKU Family", field: "CrpVmSku" },
    { headerName: "Azure Portal Display Name", field: "PortalVmSku" },
  ];
  private batchColumnDef: ColDef[] = [
    { headerName: "CRP VM SKU Family", field: "CrpVmSku" },
    { headerName: "Azure Portal Display Name", field: "PortalVmSku" },
  ];
  private armResourceTypeColumnDef: ColDef[] = [
    { headerName: "Namespace", field: "Namespace" },
    { headerName: "Resource Type Name", field: "ResourceTypeName" },
    //{ headerName: "Required Features Policy", field: "RequiredFeaturesPolicy" },
    //{ headerName: "End Point Uri", field: "EndpointUri" },
    { headerName: "Required Features", field: "RequiredFeatures" },
    { headerName: "Lionrock Can Assign", field: "FeatureToAssign" },
    { headerName: "Can Access", field: "CanAccess" },
  ];
  private vmDiskColumnDef: ColDef[] = [
    { headerName: "Disk Quota Type Display Name", field: "QuotaName" },
    { headerName: "Disk Quota Type", field: "QuotaType" },
    { headerName: "Max Quota Limit", field: "MaxQuotaLimit" },
  ];
  protected gridApi: GridApi;
  protected columnApi: ColumnApi;

  constructor(private apiService: ApiService, private loadingService: LoadingScreenService, private modalService: ModalService) {
    super();
  }

  ngOnInit(): void {
    this.loadingService.setLoading(true);
    // Set AG grid options
    this.gridOptions = this.getGridOptions(this.columnDef);
    this.hdiGridOptions = this.getGridOptions(this.hdiColumnDef);
    this.batchGridOptions = this.getGridOptions(this.batchColumnDef);
    this.armResourceTypeGridOptions = this.getGridOptions(this.armResourceTypeColumnDef);
    this.vmDiskGridOptions = this.getGridOptions(this.vmDiskColumnDef);

    forkJoin([
      this.apiService.getRegionList(),
      this.apiService.getVmSkusByRegion(),
      this.apiService.getRegionSupportInfo(),
      this.apiService.getHdiSkus(),
      this.apiService.getBatchSkus(),
      this.apiService.getArmResourceTypes(),
    ]).subscribe(([regions, vmSkus, regionSupportInfos, hdiSkus, batchSkus, armResourceTypes]) => {
      if (regions) {
        var filterRegions = regions.filter(region => allowedCommonRegionStatuses.includes(region.Status));
        // regions
        this.regionList = filterRegions;
        this.allRegion = filterRegions.map((r, i) => {
          return ApiService.createRequestRegionFromRegion(r, i);
        });
        this.clouds = filterRegions.map((r) => r.CloudName).filter((cloud, i, self) => self.indexOf(cloud) === i);
      }
      this.vmSkus = vmSkus; // VmSkus
      this.regionSupportInfo = regionSupportInfos; // RegionSupportInfos
      this.hdiSkus = hdiSkus; // HdiSkus
      this.batchSkus = batchSkus;
      this.armResourceTypes = armResourceTypes; // Arm Resource Type// Regions Support Vm Disk
      this.loadingService.setLoading(false);
    });
  }

  subscriptionInfo(): void {
    if (this.region && this.subscriptionId) {
      if (this.deploymentType === "ARM") {
        this.subscriptionInfoARM();
      }
      else if (this.deploymentType === "RDFE") {
        this.subscriptionInfoRDFE();
      }
    }
    else {
      this.buildSubscriptionInfo();
    }
  }

  async subscriptionInfoARM(): Promise<void> {
    if (this.region && this.subscriptionId) {
      this.loadingService.setLoading(true);

      // Get SubscriptionSupportInfo if region or subscription are changed
      if (this.isRegionOrSubChanged()) {
        try {
          this.subscriptionSupportInfo = await this.apiService.getSubscriptionSupportInfo(this.subscriptionId, this.region.label).toPromise();
        }
        catch (error) {
          this.loadingService.setLoading(false);
          const message = `Failed to get subscription in this cloud. Please verify the subscription ID and region. Error message: ${error}`;
          this.modalService.informationModal(message);
        }
        this.buildSubscriptionInfo();
      }

      this.serviceNotReady = "";
      if (this.subscriptionSupportInfo.IsRegionAccessible && (this.serviceType === ResourceType.Compute || this.serviceType === ResourceType.Hdi || this.serviceType === ResourceType.Batch)) {
        this.subscriptionSupportInfoARM = undefined;
        this.apiService.getARMComputeSubscriptionSupportInfo(this.subscriptionId, this.region.label).subscribe(
          (subscriptionSupportInfoARM: SubscriptionSupportInfoARM) => {
            this.loadingService.setLoading(false);
            this.subscriptionSupportInfoARM = subscriptionSupportInfoARM;
            this.buildArmVmSubscriptionInfo();
          },
          (error: unknown) => {
            this.loadingService.setLoading(false);
            const message = `Error: ${error}` || "The subscription was not found in this cloud. Please verify the subscription ID and region.";
            this.modalService.informationModal(message);
          }
        );
      } else if (this.serviceType === ResourceType.Storage) {
        this.buildARMStorageSubscriptionInfo();
      } else if (this.serviceType === ResourceType.ArmResourceType) {
        this.buildArmResourceTypeSubscriptionInfo();
      } else if (this.serviceType === ResourceType.VmDisk) {
        this.buildVmDiskSubscriptionInfo();
      } else {
        var failedItems = [];
        if (this.subscriptionSupportInfo.Flags === null) {
          failedItems.push("Failed to get all features in this subscription");
        }
        if (this.subscriptionSupportInfo.ZoneMappings === null) {
          failedItems.push("Failed to get Availability Zones");
        }

        if (failedItems.length > 0) {
          this.modalService.informationModal(failedItems.join("<br />"));
        }

        this.loadingService.setLoading(false);
      }

      this.previousRegion = this.region.label;
      this.previousSub = this.subscriptionId;
    } else {
      const message = "Please enter region and subscription.";
      this.modalService.informationModal(message);
    }
  }

  subscriptionInfoRDFE(): void {
    if (this.cloud && this.subscriptionId) {
      this.loadingService.setLoading(true);
      this.subscriptionSupportInfoRDFE = undefined;
      this.apiService.getRDFESubscriptionSupportInfo(this.subscriptionId, this.cloud).subscribe(
        (subscriptionSupportInfoRDFE: SubscriptionSupportInfoRDFE) => {
          this.subscriptionSupportInfoRDFE = subscriptionSupportInfoRDFE;
          this.loadingService.setLoading(false);
        },
        (error: unknown) => {
          this.loadingService.setLoading(false);
          const message = `Error: ${error}` || "The subscription was not found in this cloud. Please verify the subscription ID and cloud.";
          this.modalService.informationModal(message);
        }
      );
    } else {
      const message = "Please enter cloud and subscription.";
      this.modalService.informationModal(message);
    }
  }

  regionChange(event): void {
    if (event) {
      this.serviceNotReady = "";
      this.buildCurrentRegion(event.label);
      this.subscriptionInfo();
    }
  }

  private buildCurrentRegion(regionName) {
    this.currentRegion = this.regionSupportInfo.find((r) => r.RegionName == regionName);
    if (this.currentRegion && this.currentRegion.Azs) {
      this.currentAzs = this.currentRegion.Azs;
    }
    this.currentVmSkus = this.vmSkus == null ? [] : this.vmSkus.filter((sku) => sku.RegionName == regionName).map((sku) => <VmSkuExt>sku);
    this.currentHdiSkus = this.hdiSkus == null ? [] : this.hdiSkus.filter((sku) => sku.RegionName == regionName).map((sku) => <HdiSku>sku);
    this.currentBatchSkus = this.batchSkus == null ? [] : this.batchSkus.filter((sku) => sku.RegionName == regionName).map((sku) => <BatchSku>sku);
    this.currentArmResourceTypes = this.armResourceTypes == null ? [] : this.armResourceTypes.filter((sku) => sku.Location == regionName).map((sku) => <ArmResourceTypeExt>sku);
  }

  private buildSubscriptionInfo() {
    this.currentRegionAccess = null;
    this.currentAzAccess = null;
    this.isRegionAccess = false;
    if (this.currentRegion && !this.currentRegion.AfecFlags?.length) {
      if (this.currentRegion.ArmServiceStatus) {
        this.currentRegionAccess = this.currentRegion.ArmServiceStatus != "NotAvailable" && "No restriction";
      }
    }
    if (this.currentRegion) {
      if (this.currentRegion.AzAccess == this.NoRestriction) this.currentAzAccess = "No restriction";
      if (this.currentRegion.AzAccess == this.NotAvailable) this.currentAzAccess = "Not available";
      if (this.currentRegionAccess == null) {
        const afecFlagStatus = [];
        const afecFlags = this.currentRegion.AfecFlags.map(flag => flag.toLowerCase());
        if (this.subscriptionSupportInfo) {
          this.subscriptionSupportInfo.Flags?.map(flag => {
            if (afecFlags.includes(flag.toLowerCase())) {
              afecFlagStatus.push(flag);
              this.isRegionAccess = true;
            }
          });
        }

        if (this.isRegionAccess) {
          this.currentRegionAccess = `Yes: ${afecFlagStatus.join(",")}`;
        }
        else {
          this.currentRegionAccess = "No";
        }
      }
      if (this.currentAzAccess == null && this.subscriptionSupportInfo?.Flags) {
        this.currentAzAccess =
          (this.subscriptionSupportInfo.Flags.some((flag) => this.currentRegion.AzAfecFlags.toLowerCase() === flag.toLowerCase()) && "Yes") || "No";
      }
    }
  }

  private buildArmVmSubscriptionInfo(): void {
    if (this.currentRegion && this.subscriptionSupportInfoARM) {
      const regionName = this.currentRegion.RegionName;
      if (this.vmSkus && this.hasDataForSubscriptionSupportInfoARM()) {
        this.currentVmSkus = this.vmSkus
          .filter((sku) => sku.RegionName == regionName)
          .map((sku) => {
            const s = <VmSkuExt>{ ...sku };
            const usage = this.subscriptionSupportInfoARM.SkuUsages?.find((u) => u.VmFamily === sku.CrpVmSkuId);
            if (usage) {
              s.Quota = usage.CurrentQuota;
              s.Usage = usage.CurrentUsage;
            } else {
              s.Quota = 0;
              s.Usage = 0;
            }
            s.Restricted = this.subscriptionSupportInfoARM.SkuRestrictions?.some(s => s === sku.CrpVmSku);
            s.RestrictedZonal = this.subscriptionSupportInfoARM.SkuRestrictionsZonal?.some(s => s === sku.CrpVmSku);

            return s;
          });
      }
      if (!this.subscriptionSupportInfoARM.SkuRestrictions?.length || !this.subscriptionSupportInfoARM.SkuUsages?.length) {
        this.serviceNotReady = ResourceType.Compute;
      }
    }
  }

  private buildARMStorageSubscriptionInfo() {
    this.apiService.getARMStorageSubscriptionSupportInfo(this.subscriptionId, this.region.label).subscribe(
      (response) => {
        if (!response) {
          this.serviceNotReady = ResourceType.Storage;
        }
        this.subscriptionRegionSupportInfo = response;
        this.loadingService.setLoading(false);
      },
      (error: unknown) => {
        this.loadingService.setLoading(false);
        const message = `Error: ${error}` || "The subscription was not found in this region. Please verify the subscription ID and region.";
        this.modalService.informationModal(message);
      }
    );
  }

  private buildArmResourceTypeSubscriptionInfo() {
    this.apiService.getArmResourceTypeSubscriptionSupportInfo(this.subscriptionId, this.region.label).subscribe(
      (response) => {
        if (!response) {
          this.serviceNotReady = ResourceType.ArmResourceType;
          this.currentArmResourceTypes = [];
        }
        else {
          this.supportedAfecs = response;
          this.currentArmResourceTypes.map(r => {
            if (r.RequiredFeatures.length == 0) {
              r.CanAccess = true;
            }
            else {
              var requiredFeaturesList = r.RequiredFeatures.split(",");
              r.CanAccess = (requiredFeaturesList.filter(f => this.supportedAfecs.includes(f))).length > 0;
            }
          });
          this.currentArmResourceTypes = [...this.currentArmResourceTypes];
        }
        this.loadingService.setLoading(false);
      },
      (error: unknown) => {
        this.loadingService.setLoading(false);
        const message = `Error: ${error}` || "The subscription was not found in this region. Please verify the subscription ID and region.";
        this.modalService.informationModal(message);
      }
    );
  }

  private buildVmDiskSubscriptionInfo() {
    this.apiService.getVmDiskSubscriptionSupportInfo(this.subscriptionId, this.region.label).subscribe(
      (response) => {
        if (!response) {
          this.serviceNotReady = ResourceType.VmDisk;
          this.currentVmDisks = [];
        }
        else {
          this.subscriptionVmDiskSupportInfo = response;
          this.currentVmDisks = [...response];
        }
        this.loadingService.setLoading(false);
      },
      (error: unknown) => {
        this.loadingService.setLoading(false);
        const message = `Error: ${error}` || "The subscription was not found in this region. Please verify the subscription ID and region.";
        this.modalService.informationModal(message);
      }
    );
  }

  isArm(): boolean {
    return this.deploymentType == "ARM";
  }

  showRegionInfo(): boolean {
    return this.currentRegion != null;
  }

  showRdfeInfo(): boolean {
    return !this.isArm();
  }

  hasAfecFlags(): boolean {
    if (this.currentRegion.AfecFlags?.length) {
      return true;
    }
    return false;
  }

  serviceChange(event): void {
    console.log(event);
  }

  showRegionalQuota(): boolean {
    return this.serviceType === ResourceType.Compute &&
      this.hasDataForSubscriptionSupportInfoARM() &&
      this.subscriptionSupportInfoARM?.RegionalVmQuota > -1;
  }

  onGridReady(params: GridOptions): void {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;
  }

  async exportCurrentView(): Promise<void> {
    const params = {
      exportMode: "xlsx",
      skipHeader: false,
      columnGroups: false,
      skipGroups: false,
      skipFooters: false,
      skipPinnedTop: false,
      skipPinnedBottom: false,
      allColumns: false,
      onlySelected: false,
      onlySelectedAllPages: false,
      fileName: "ExtendInformation-" + PreprocessDateTime(Date.now(), "yyyyMMdd-HHmm", "en-US"),
      sheetName: "Extend Information",
    } as ExcelExportParams;

    this.loadingService.setLoading(true);
    this.gridApi.exportDataAsExcel(params);
    this.loadingService.setLoading(false);
  }

  hasDataForSubscriptionSupportInfoARM(): boolean {
    return this.subscriptionSupportInfoARM && (
      this.subscriptionSupportInfo?.ZoneMappings != null || this.subscriptionSupportInfoARM.SkuRestrictions != null ||
      this.subscriptionSupportInfoARM.SkuRestrictionsZonal != null || this.subscriptionSupportInfoARM.SkuUsages != null);
  }

  sizeColumns(): void {
    if (this.gridApi && this.columnApi) {
      this.gridApi.sizeColumnsToFit();
      window.setTimeout(() => {
        const colIds = this.columnApi.getAllColumns()?.map((c) => c.getColId());
        if (colIds && colIds.length > 0) {
          this.columnApi.autoSizeColumns(colIds);
        }
      }, 50);
    }
  }

  getAccessInfo(access: string): string {
    return access ?? this.subscriptionPrompt;
  }

  getGridOptions(colDefs: (ColDef | ColGroupDef)[] | null): GridOptions {
    return extendDefaultOptions({
      columnDefs: colDefs,
      context: this, // passed context for customized component callback
      frameworkComponents: {
        // register angular component for customized column header
        // https://www.ag-grid.com/javascript-grid-header-rendering/#example-header-component
        customTooltip: CustomTooltipComponent,
        serviceTypeRenderer: ServiceTypeRendererComponent,
      },
      animateRows: true,
      onFirstDataRendered() {
        this.gridApi?.sizeColumnsToFit();
      },
      paginationPageSize: 20,
    });
  }

  isRegionOrSubChanged(): boolean {
    return this.previousRegion != this.region.label || this.previousSub != this.subscriptionId;
  }
}
