import { ColDef } from "ag-grid-community";
import { PlanRegion } from "../generated-models/PlanRegion";
import { ApiService } from "../services";
import { ServiceTypeRendererComponent } from "../shared/az-mapping-modal/service-type-renderer.component";
import { ColParams } from "../shared/grid/ag-grid";
import { GetCustomerRequestDetailsPageLink, GetDependencyLinks, GetSubRequestDetailsPageLink } from "./common-helper";
import { JsonCellRenderer, QuotaCellRenderer } from "./gridCellRenderer";
import { agDateColumnFilter } from "./gridHelper";
import { DateColumnFormatter, PreprocessDateTime } from "./view-field-mapping";

export const CreatedTime: ColDef = getColDef("Created Time", "CreatedTime", {
  cellRenderer: (params) => PreprocessDateTime(params.value),
  filter: "agDateColumnFilter",
  filterParams: {
    comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue),
  },
});
export const CompletedTime: ColDef = getColDef("Completed Time", "CompletedTime", {
  cellRenderer: (params) => PreprocessDateTime(params.value),
  filter: "agDateColumnFilter",
  filterParams: {
    comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue),
  },
});
export const AssignedTime: ColDef = getColDef("Assigned Time", "AssignedTime", {
  cellRenderer: (params) => PreprocessDateTime(params.value),
  filter: "agDateColumnFilter",
  filterParams: {
    comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue),
  },
});
export const RegionalPlanStatus: ColDef = getColDef("Status", "RegionalPlanStatus");
export const ApprovedTime: ColDef = getColDef("Approved Time", "ApprovedTime", {
  cellRenderer: (params) => PreprocessDateTime(params.value),
  filter: "agDateColumnFilter",
  filterParams: {
    comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue),
  },
});
export const ValidToDate: ColDef = getColDef("Valid To Time", "ValidToDate", {
  cellRenderer: (params) => PreprocessDateTime(params.value),
  filter: "agDateColumnFilter",
  filterParams: {
    comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue),
  },
});
export const ExpiryDate: ColDef = getColDef("Expiry Date", "ExpiryDate", {
  cellRenderer: (params) => PreprocessDateTime(params.value),
  filter: "agDateColumnFilter",
  filterParams: {
    comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue),
  },
});
export const BuildoutStartDate: ColDef = getColDef("Needed By", "StartDate", {
  cellRenderer: (params) => PreprocessDateTime(params.value),
  filter: "agDateColumnFilter",
  filterParams: {
    comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue),
  },
});
export const IsPendingCCO: ColDef = getColDef("CCO Approval", "IsPendingCCO");
export const Email: ColDef = getColDef("Email", "Email");
export const Requestor: ColDef = getColDef("Requestor", "Requestor");
export const Cloud: ColDef = getColDef("Cloud", "Cloud");
export const RingLevel: ColDef = getColDef("Ring Level", "RingLevel");
export const Ring: ColDef = getColDef("Ring", "Ring");
export const FfpPlanType: ColDef = getColDef("Type", "Type");
export const Submitter: ColDef = getColDef("Submitter", "Submitter", { minWidth: 200 });
export const SKU: ColDef = getColDef("SKU", "SKU", { minWidth: 200 });
export const Sku: ColDef = getColDef("Sku", "Sku");
export const RequestSource: ColDef = getColDef("Request Source", "RequestSource", { minWidth: 150 });
export const ArmRegionName: ColDef = getColDef("Region", "ArmRegionName");
export const TicketStatus: ColDef = getColDef("Ticket Status", "State");
export const Title: ColDef = getColDef("Title", "Title");
export const AssignedTo: ColDef = getColDef("Assigned To", "AssignedTo");
export const AlreadyAssigned: ColDef = getColDef("Latest Version Assigned", "AlreadyAssigned");
export const LatestVersion: ColDef = getColDef("Latest Version", "Version", { filter: "agNumberColumnFilter" });
export const AssignedVersion: ColDef = getColDef("Assigned Version", "AssignedVersion");
export const Version: ColDef = getColDef("Version", "Version", { filter: "agNumberColumnFilter" });
export const PlanVersion: ColDef = getColDef("Version", "PlanVersion", { filter: "agNumberColumnFilter" });
export const DeploymentScope: ColDef = getColDef("Deployment Scope", "DeploymentScope");
export const AssignedBy: ColDef = getColDef("Assigned By", "AssignedBy");
export const RequestStatus: ColDef = getColDef("Request Status", "RequestStatus");
export const RequestServiceType: ColDef = getColDef("Request Service Type", "RequestServiceType");
export const Purpose: ColDef = getColDef("Purpose", "Purpose");
export const TicketId: ColDef = getColDef("Ticket ID", "Id", {
  sortable: true,
  checkboxSelection: true,
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  cellRenderer: (params) => {
    if (!params.value) {
      return "";
    }
    const id: string = params.value.toString();
    if (id === "-1") {
      return id;
    }

    return `<a href="${params.data.Url}" rel="noopener noreferrer" target="_blank">${id}</a>`;
  },
});
export const QMSError: ColDef = getColDef("Category", "ManualFlag");
export const DaysSinceCreated: ColDef = getColDef("Days Since Created", "CreatedTime", {
  sortable: true,
  cellStyle: (params) => {
    if (params.value > 30) {
      return { color: "red" };
    } else {
      return null;
    }
  },
  valueGetter: (params) => {
    getDurationByDay(params.data.CreatedTime);
    const currentDate = new Date().getTime();
    const createDate = new Date(params.data.CreatedTime).getTime();
    const day = 1000 * 60 * 60 * 24;
    const dayDiff = Math.floor((currentDate - createDate) / day);
    return dayDiff;
  },
  filter: "agNumberColumnFilter",
});
export const RequestId: ColDef = getColDef("Request ID", "RequestId", {
  cellRenderer: (params) => {
    if (!params.value) {
      return "";
    }
    const [parentRequestId, subRequestId] = params.value.split("-");
    const url = ApiService.generateSubRequestHyperLink(parentRequestId, subRequestId);
    return url;
  },
});
export const CreatedDate: ColDef = getColDef("Created Date", "CreatedTime", {
  filter: "agDateColumnFilter",
  filterParams: {
    comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue),
  },
});
export const LastCommentDate: ColDef = getColDef("Last Comment Date", "LastCommentDate", {
  filter: "agDateColumnFilter",
  filterParams: {
    comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue),
  },
});
export const FirstComments: ColDef = getColDef("First Comments", "FirstComment", {
  cellRenderer: (params) => {
    return params.value;
  },
  autoHeight: true,
});
export const Comment: ColDef = getColDef("Comment", "Comment");
export const RequestDisplayServiceType: ColDef = getColDef("Request Service Type", "RequestDisplayServiceType", {
  minWidth: 200,
  cellRendererFramework: ServiceTypeRendererComponent,
});
export const ValidTo: ColDef = getColDef("Valid To", "ValidTo", {
  cellRenderer: (params) => {
    if (params.value === "GA") {
      return "MA";
    }
    return params.value;
  }
});
export const Status: ColDef = getColDef("Status", "Status");
export const TicketIds: ColDef = getColDef("RDQuota IDs", "TicketIds");
export const Quota: ColDef = getColDef("Quota", "Quota", {
  cellRenderer: (params) => QuotaCellRenderer(params),
  filter: "agNumberColumnFilter",
});
export const AdditionalParams: ColDef = getColDef("AdditionalParams", "ServiceParams", {
  cellRenderer: (params) => JsonCellRenderer(params),
});
export const Dependencies: ColDef = getColDef("Dependencies", "Dependencies", {
  cellRenderer: (params) => {
    const dependencies: string = params.value;
    if (!dependencies) {
      return "";
    }
    return GetDependencyLinks(dependencies);
  },
});
export const Justification: ColDef = getColDef("Justification", "Justification");
export const IdApprove: ColDef = getColDef("Billing Account ID", "BillingAccountId", {
  checkboxSelection: true,
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  cellRenderer: (params) => GetCustomerRequestDetailsPageLink(params.data.BillingAccountId, params.data.Region),
});
export const IdAll: ColDef = getColDef("Billing Account ID", "BillingAccountId", {
  cellRenderer: "agGroupCellRenderer",
  cellRendererParams: {
    suppressCount: true, // turn off the row count
    suppressDoubleClickExpand: true, // turn off double click for expand
    innerRenderer: (params) => GetCustomerRequestDetailsPageLink(params.data.BillingAccountId, params.data.Region),
  },
});
export const BillingAccountId: ColDef = getColDef("Billing Account ID", "BillingAccountId");
export const UtilizeDate: ColDef = getColDef("Utilize Date", "UtilizeDate", {
  cellRenderer: (params) => PreprocessDateTime(params.value),
  filter: "agDateColumnFilter",
  filterParams: {
    comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue),
  },
});
export const IdDetails: ColDef = getColDef("Billing Account ID", "BillingAccountId");
export const SubscriptionsCount: ColDef = getColDef("Subscriptions Count", "SubscriptionsCount", { filter: "agNumberColumnFilter" });
export const TPID: ColDef = getColDef("TPID", "TPID");
export const OfferType: ColDef = getColDef("Offer Type", "OfferType");
export const BatchId: ColDef = getColDef("Batch Id", "BatchId");
export const AGApproved: ColDef = getColDef("AG Approved", "AGApproved");
export const GCTApproved: ColDef = getColDef("GCT Approved", "GCTApproved");
export const ApprovedBy: ColDef = getColDef("Approved By", "ApprovedBy");
export const FulfillChannel: ColDef = getColDef("Fulfill Channel", "FulfillChannel");
export const Notes: ColDef = getColDef("Notes", "Notes", {
  tooltipField: "Notes",
  maxWidth: 500,
  cellRenderer: (params) => GetSubRequestDetailsPageLink(params.data.RequestId, params.data.Notes),
});
export const PriorityString: ColDef = getColDef("Priority", "PriorityString");
export const Stage: ColDef = getColDef("Stage", "Stage");
export const IsExternalDisplay: ColDef = getColDef("Customer Sub", "IsExternalDisplay");
export const IsHoboDisplay: ColDef = getColDef("Hobo", "IsHoboDisplay");
export const ServiceTreeId: ColDef = getColDef("ServiceTree Id", "ServiceTreeId");
export const ServiceTeam: ColDef = getColDef("Service Team", "ServiceTeam");
export const CreatedBy: ColDef = getColDef("Created By", "CreatedBy");
export const SubscriptionBindings: ColDef = getColDef("Subscription Bindings", "SubscriptionBindings");
export const PropertyBindings: ColDef = getColDef("Resource Bindings", "PropertyBindings");
export const SubscriptionKey: ColDef = getColDef("Subscription Name In Plan", "SubscriptionKey");
export const IsHobo: ColDef = getColDef("Is Hobo", "IsHobo");
export const BindingKey: ColDef = getColDef("Binding Key", "BindingKey");
export const BindingType: ColDef = getColDef("Binding Type", "BindingType");
export const BindingValue: ColDef = getColDef("Binding Value", "BindingValue");
export const ApprovalType: ColDef = getColDef("Approval Type", "ApprovalType");
export const SubscriptionType: ColDef = getColDef("Subscription Type", "SubscriptionType");
export const Enabled: ColDef = getColDef("Enabled", "Enabled");
export const Id: ColDef = getColDef("Id", "Id");
export const ResourceType: ColDef = getColDef("Resource Type", "ResourceType");
export const EmailId: ColDef = getColDef("Email Id", "EmailId");
export const Type: ColDef = getColDef("Type", "Type");
export const To: ColDef = getColDef("To", "To");
export const Cc: ColDef = getColDef("Cc", "Cc");
export const MessageId: ColDef = getColDef("Message Id", "MessageId");
export const EmailBatchID: ColDef = getColDef("Batch Id", "BatchId");
export const MessageStatus: ColDef = getColDef("Message Status", "MessageStatus");
export const Details: ColDef = getColDef("Details", "Details");
export const RetryCount: ColDef = getColDef("Retry Count", "RetryCount");
export const IsCappedForCapacityOrder: ColDef = getColDef("Is Capped Region", "IsCappedForCapacityOrder");
export const LatestCommitVersion: ColDef = getColDef("Latest Commit Version", "Version", {
  cellRenderer: (params) => (params.value > 0 ? params.value : "N/A"),
  filter: "agNumberColumnFilter",
});
export const CustomFilter: ColDef = getColDef("Custom Filter", null, {
  colId: "customFilter",
  valueGetter: () => {
    return false;
  },
  filterParams: {
    values: ["true", "false"],
    newRowsAction: "keep",
  },
  hide: true,
});
export const ServiceOwnerApproved: ColDef = getColDef("ServiceOwner Approved", "ServiceOwnerApproved");
export const FfpAdminApproved: ColDef = getColDef("FfpAdmin Approved", "FfpAdminApproved");
export const ProjectName: ColDef = getColDef("Project Name", "ProjectName");
export const BusinessScenario: ColDef = getColDef("Business Scenario", "BusinessScenario");
export const BuildoutPSL: ColDef = getColDef("Buildout PSL", "BuildoutPSL");

// function
export const SubmitTime = (headerName = "Submit Time", field = "SubmitTime"): ColDef =>
  getColDef(headerName, field, {
    cellRenderer: (params) => PreprocessDateTime(params.value),
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue),
    },
  });

export const ProjectOidColDef = (field: string, properties?: Record<string, unknown>): ColDef => getColDef("Project Oid", field, properties);
export const RegionColDef = (field: string, properties?: Record<string, unknown>): ColDef => getColDef("Region", field, properties);
export const EdgeZoneColDef = (field: string, properties?: Record<string, unknown>): ColDef => getColDef("EdgeZone", field, properties);
export const SubscriptionId = (field: string, properties?: Record<string, unknown>): ColDef =>
  getColDef("Subscription ID", field, { ...properties, minWidth: 300 });

function getDurationByDay(dateString: string): number {
  const currentDate = new Date().getTime();
  const createDate = new Date(dateString).getTime();
  const day = 1000 * 60 * 60 * 24;
  const dayDiff = Math.floor((currentDate - createDate) / day);
  return dayDiff;
}

function GetValidTo(params: ColParams<PlanRegion, any>) {
  if (params.data.ValidTo === "Custom" && params.data.ValidToDate) {
    return PreprocessDateTime(params.data.ValidToDate, DateColumnFormatter);
  } else {
    return params.data.ValidTo;
  }
}

function getColDef(headerName: string, field: string, properties?: Record<string, unknown>): ColDef {
  return {
    headerName: headerName,
    field: field,
    ...properties,
  } as ColDef;
}
