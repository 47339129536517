<div role="dialog" aria-modal="true">
  <div class="modal-header">
    <h2 class="modal-title h4">Onboard a new service plan</h2>
  </div>
  <form #ngForm="ngForm" (ngSubmit)="ngForm.valid && submit()">
    <div class="modal-body">
      <label style="width: 100%" *ngIf="customTeam === false; else CustomTeamOID">
        <span>Select the service that you own</span>
        <ng-select
          name="TeamName"
          [(ngModel)]="team"
          [items]="teamList"
          groupBy="ring"
          placeholder="Start typing"
          [virtualScroll]="true"
          required
          data-test="TeamName"
          #teamName="ngModel">
          <ng-template ng-option-tmp let-item="item">
            <ng-container>
              <label title="{{ item.label }}" attr.data-test="{{ item.label }}">
                {{ item.label }}
              </label>
            </ng-container>
          </ng-template>
        </ng-select>
      </label>
      <ng-template #CustomTeamOID>
        <input [(ngModel)]="teamOid" class="form-control" type="text" name="TeamOID" />
      </ng-template>
      <label style="width: 100%">
        <input type="checkbox" [(ngModel)]="customTeam" name="CustomTeam" />
        <span class="ml-1" for="CustomTeam">My service is not in the list. Let me enter the service tree ID directly.</span>
      </label>

      <label style="width: 100%" *ngIf="isAdmin">
        <input type="checkbox" [(ngModel)]="onboardPlannedQuotaV2" name="OnboardPlannedQuotaV2" />
        <span class="ml-1" for="OnboardPlannedQuotaV2">Onboard to PlannedQuota V2.</span>
      </label>

      <label style="width: 100%" *ngIf="onboardPlannedQuotaV2">
        <span>Select the business scenario of the plan</span>
        <ng-select
          name="planTypeName"
          [(ngModel)]="planType"
          [items]="planTypeList"
          placeholder="Start typing"
          [virtualScroll]="true"
          required
          data-test="planTypeName"
          #planTypeName="ngModel">
          <ng-template ng-option-tmp let-item="item">
            <ng-container>
              <label title="{{ item }}" attr.data-test="{{ item }}">
                {{ item }}
              </label>
            </ng-container>
          </ng-template>
        </ng-select>
      </label>
    </div>
    <div class="modal-footer">
      <button type="submit" class="btn btn-primary" [disabled]="ngForm.invalid || disableSubmit()" data-test="Submit">OK</button>
      <button type="button" class="btn btn-secondary" (click)="activeModal.close()">Cancel</button>
    </div>
  </form>
</div>
