import { Component, HostListener, OnInit } from "@angular/core";
import { ColDef, ColumnApi, FilterChangedEvent, GridApi, GridOptions, RowNode } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { ModalService } from "src/app/shared/modal.service";
import { LinkCellComponent } from "src/app/shared/grid/link-cell.component";
import { PreprocessDateTime } from "src/app/utility/view-field-mapping";
import { PlanSubmission } from "src/app/generated-models/PlanSubmission";
import * as CDs from "src/app/utility/commonColDef";
import { agDateColumnFilter, extendDefaultOptions, setupFilterByLocalData, cacheFilterStatsLocal } from "../../utility/gridHelper";
import { BaseComponent } from "../../shared/base.component";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ApiService } from "../../services";
import { RegionStatusEnum } from "src/app/shared/enums/plan-enums";
import { Region } from "src/app/generated-models";
import { viewRegionAgnosticPlanHistoryCellComponentParams } from "./ag-grid-column-definition";
import { exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";
import { IUserProfile, PlanAssignmentCSVRecord, PlanListRecord, RegionStatus, allowedCommonRegionStatuses } from "src/app/npr-request.model";

interface RowDataForPlan {
  ServiceTeam: string;
  ServiceTreeId: string;
  Ring: string;
  Version: number;
  CreatedTime: Date;
  CreatedBy: string;
  Comment: string;
  RegionStatus: string;
  Stage: string;
  AssignedVersion?: number;
  AlreadyAssigned?: string;
  AssignedBy?: string;
  AssignedTime?: Date | null;
  ApprovedBy?: string;
  ApprovedTime?: Date | null;
  ValidTo?: string;
  ValidToDate?: Date | null;
  Purpose?: string;
  RegionalPlanStatus?: string;
}

interface RowDataForRegion {
  RegionName: string;
  RegionStatus: string;
  Stage: string;
  AssignedVersion?: number;
  AlreadyAssigned?: string;
  AssignedBy?: string;
  AssignedTime?: Date | null;
  ApprovedBy?: string;
  ApprovedTime?: Date | null;
  ValidTo?: string;
  ValidToDate?: Date | null;
  Purpose?: string;
  RegionalPlanStatus?: string;
  IsCappedForCapacityOrder: boolean;
}

@Component({
  templateUrl: "./plans-assignment.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})
export class PlansAssignmentComponent extends BaseComponent implements OnInit {
  regions: Region[] = [];
  selectedRegion: Region;
  regionEnum = RegionStatusEnum;
  userProfile: IUserProfile = null;

  rowDataForPlan: RowDataForPlan[] = [];
  rowDataForRegion: RowDataForRegion[] = [];

  gridOptionsForPlan: GridOptions;
  gridOptionsForRegion: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;

  plans: PlanListRecord[] = [];
  selectedPlan: PlanListRecord;

  planRegions: PlanRegion[] = [];

  hideAlreadyAssigned = false;
  hasRowSelected = false;

  assignPlansToOneRegion = true;
  keyFilterStatusOneRegion = "filter:multiple-to-one";
  keyFilterStatusMultiRegions = "filter:one-to-multiple";
  maxRegionNumOneTime = 3;
  canAssign = false;
  assignTooManyRegions = false;
  assignMultiStages = false;

  constructor(
    private apiService: ApiService,
    private modalService: ModalService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService
  ) {
    super();
  }

  async ngOnInit() {
    this.hasRowSelected = false;

    const PlansColumnDefinition: ColDef[] = [
      {
        colId: "customFilter",
        valueGetter: (params: ColParams<RowDataForPlan, void>) => {
          if (this.hideAlreadyAssigned) {
            return this.isAssignable(params.data);
          }

          return true;
        },
        filterParams: {
          values: ["true", "false"],
          newRowsAction: "keep",
        },
        hide: true,
      },
      {
        ...CDs.ServiceTreeId,
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
      },
      CDs.ServiceTeam,
      CDs.Ring,
      {
        ...CDs.LatestVersion,
        cellRendererFramework: LinkCellComponent,
        cellRendererParams: viewRegionAgnosticPlanHistoryCellComponentParams,
      },
      CDs.Stage,
      CDs.CreatedBy,
      {
        headerName: "Submit Time",
        field: "CreatedTime",
        cellRenderer: (params) => PreprocessDateTime(params.value),
        filter: "agDateColumnFilter",
        filterParams: {
          comparator: (filterLocalDateAtMidnight, cellValue) => agDateColumnFilter(filterLocalDateAtMidnight, cellValue),
        },
      },
      CDs.AlreadyAssigned,
      CDs.AssignedVersion,
      CDs.AssignedBy,
      CDs.AssignedTime,
      CDs.ApprovedBy,
      CDs.ApprovedTime,
      CDs.Purpose,
      CDs.ValidTo,
      CDs.RegionalPlanStatus,
    ];

    this.gridOptionsForPlan = extendDefaultOptions({
      columnDefs: PlansColumnDefinition,
      paginationPageSize: this.initPageSize,
      isRowSelectable: (node: RowNode) => {
        return this.isAssignable(node.data);
      },
      enableRangeSelection: false,
      rowSelection: "multiple",
      animateRows: true,
    });

    const RegionsColumnDefinition: ColDef[] = [
      {
        colId: "customFilter",
        valueGetter: (params: ColParams<RowDataForRegion, void>) => {
          if (this.hideAlreadyAssigned) {
            return this.isAssignable(params.data);
          }

          return true;
        },
        filterParams: {
          values: ["true", "false"],
          newRowsAction: "keep",
        },
        hide: true,
      },
      {
        ...CDs.RegionColDef("RegionName"),
        checkboxSelection: true,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
      },
      CDs.Stage,
      CDs.AlreadyAssigned,
      CDs.AssignedVersion,
      CDs.AssignedBy,
      CDs.AssignedTime,
      CDs.RegionalPlanStatus,
      CDs.ApprovedBy,
      CDs.ApprovedTime,
      CDs.Purpose,
      CDs.ValidTo,
      CDs.IsCappedForCapacityOrder,
    ];

    this.gridOptionsForRegion = extendDefaultOptions({
      columnDefs: RegionsColumnDefinition,
      suppressPaginationPanel: true,
      isRowSelectable: (node: RowNode) => {
        return this.isAssignable(node.data);
      },
      enableRangeSelection: false,
      rowSelection: "multiple",
      animateRows: true,
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent' },
          { statusPanel: 'agTotalRowCountComponent' },
          { statusPanel: 'agFilteredRowCountComponent' },
          { statusPanel: 'agSelectedRowCountComponent' },
          { statusPanel: 'agAggregationComponent' }
        ]
      }
    });

    this.apiService.getUserProfile().then(async (response) => {
      this.userProfile = response;
      if (response) {
        this.gridApi?.showLoadingOverlay();

        // Region List
        // includeDisabled = false, includeAirGapped = false
        const regionListResponse = await this.apiService.getRegionList(false, false).toPromise();
        if (!regionListResponse) {
          console.warn("No region obtained from server.");
        } else {
          this.regions = regionListResponse
            .filter(region => allowedCommonRegionStatuses.includes(region.Status))
            .sort((region1, region2) => {
              return this.regionEnum[region1.Status.replace(/\s/g, "")] - this.regionEnum[region2.Status.replace(/\s/g, "")];
            });
        }
      }
    });

    this.loadingService.setLoading(true);

    // Plan List
    const planListResponse = await this.apiService.getPlans().toPromise();
    if (!planListResponse) {
      console.warn("No plan obtained from server.");
    } else {
      this.plans = planListResponse
        .filter((p) => p.Version > 0)
        .map((p) => ({ ...p, Label: `${p.ServiceTeam} (${p.ServiceTreeId})` } as PlanListRecord));
    }

    await this.getLatestRegionalPlans();

    this.loadingService.setLoading(false);
  }

  onGridReady(params: GridOptions): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setFilterModel({
      customFilter: ["true"],
    });
  }

  async getLatestRegionalPlans(): Promise<void> {
    return this.apiService
      .getLatestRegionalPlans()
      .toPromise()
      .then(
        (response) => {
          this.planRegions = response;
          setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStatusOneRegion);
          setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStatusMultiRegions);
        },
        (e: any) => {
          this.planRegions = [];
          this.notificationService.error(e);
        }
      );
  }

  onRegionChanged(): void {
    this.rowDataForPlan = [];
    if (this.selectedRegion) {
      const rowData: RowDataForPlan[] = [];

      this.plans.forEach((plan) => {
        var stages = plan.Stages.split(",");
        stages.forEach((stage) => {
          const row: RowDataForPlan = {
            ServiceTeam: plan.ServiceTeam,
            ServiceTreeId: plan.ServiceTreeId,
            Ring: plan.Ring,
            Version: plan.Version,
            RegionStatus: this.selectedRegion.Status,
            Stage: stage,
            CreatedTime: plan.CreatedTime,
            CreatedBy: plan.CreatedBy,
            Comment: plan.Comment,
            Purpose: plan.Purpose,
          };

          rowData.push(row);
        });
      });

      rowData.forEach((row) => {
        row.AlreadyAssigned = "No";

        const planRegion = this.planRegions.find(
          (p) => p.Region === this.selectedRegion.RegionName && p.ServiceTreeId === row.ServiceTreeId && p.Stage === row.Stage
        );
        if (planRegion) {
          if (planRegion.Version === row.Version) {
            row.AlreadyAssigned = "Yes";
          }
          row.AssignedVersion = planRegion.Version;
          row.AssignedBy = planRegion.Submitter;
          row.AssignedTime = planRegion.SubmitTime;
          row.ApprovedBy = planRegion.ApprovedBy;
          row.ApprovedTime = planRegion.ApprovedTime;
          row.ValidTo = planRegion.ValidTo;
          row.ValidToDate = planRegion.ValidToDate;
          row.Purpose = planRegion.Purpose;
          row.RegionalPlanStatus = planRegion.Status;
        }
      });
      this.rowDataForPlan = rowData;
      this.hasRowSelected = false;
      setTimeout(() => {
        this.gridColumnApi.autoSizeAllColumns();
      }, 100);
      setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStatusOneRegion);
    }
  }

  onPlanChanged(): void {
    this.rowDataForRegion = [];

    if (this.selectedPlan) {
      const rowData: RowDataForRegion[] = [];

      var stages = this.selectedPlan.Stages.split(",");
      this.regions.forEach((region) => {
        stages.forEach((stage) => {
          const row: RowDataForRegion = {
            RegionName: region.RegionName,
            RegionStatus: region.Status,
            Stage: stage,
            IsCappedForCapacityOrder: region.IsCappedForCapacityOrder,
            Purpose: this.selectedPlan.Purpose,
          };

          rowData.push(row);
        });
      });

      rowData.forEach((row) => {
        row.AlreadyAssigned = "No";

        const planRegion = this.planRegions.find(
          (p) => p.Region === row.RegionName && p.ServiceTreeId === this.selectedPlan.ServiceTreeId && p.Stage === row.Stage
        );
        if (planRegion) {
          if (planRegion.Version === this.selectedPlan.Version) {
            row.AlreadyAssigned = "Yes";
          }

          row.AssignedVersion = planRegion.Version;
          row.AssignedBy = planRegion.Submitter;
          row.AssignedTime = planRegion.SubmitTime;
          row.ApprovedBy = planRegion.ApprovedBy;
          row.ApprovedTime = planRegion.ApprovedTime;
          row.ValidTo = planRegion.ValidTo;
          row.ValidToDate = planRegion.ValidToDate;
          row.Purpose = planRegion.Purpose;
          row.RegionalPlanStatus = planRegion.Status;
        }
      });
      this.rowDataForRegion = rowData;
      this.hasRowSelected = false;
      this.canAssign = false;
      setTimeout(() => {
        this.gridColumnApi.autoSizeAllColumns();
      }, 100);
    }
    setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStatusMultiRegions);
  }

  isAssignable(row: RowDataForPlan | RowDataForRegion): boolean {
    return row.AlreadyAssigned === "No";
  }

  onHideAlreadyAssignedChanged(): void {
    this.gridApi.onFilterChanged();
  }

  async showPlanAssignmentDialog(): Promise<void> {
    try {
      var planPurpose = this.selectedPlan?.Purpose;
      const result = await this.modalService.planAssignmentModal(planPurpose);
      if (result?.purpose) {
        await this.assignPlans(result.purpose, result.startDate);
      }
    } catch {
      // For the model dialog dismiss
      return;
    }
  }

  async assignPlans(purpose: string, startDate: Date): Promise<void> {
    let planSubmissions: PlanSubmission[];

    if (this.assignPlansToOneRegion) {
      const selectedRows = this.gridApi.getSelectedRows() as RowDataForPlan[];
      planSubmissions = selectedRows.map((r) => {
        return {
          ServiceTreeId: r.ServiceTreeId,
          ServiceTeam: r.ServiceTeam,
          Version: r.Version,
          Stage: r.Stage,
          Region: this.selectedRegion.RegionName,
          Purpose: purpose,
          StartDate: startDate,
        } as PlanSubmission;
      });
    } else {
      const selectedRows = this.gridApi.getSelectedRows() as RowDataForRegion[];
      planSubmissions = selectedRows.map((r) => {
        return {
          ServiceTreeId: this.selectedPlan.ServiceTreeId,
          ServiceTeam: this.selectedPlan.ServiceTeam,
          Version: this.selectedPlan.Version,
          Stage: r.Stage,
          Region: r.RegionName,
          Purpose: purpose,
          StartDate: startDate,
        } as PlanSubmission;
      });
    }

    await this.modalService.planAssignmentResponseModal(planSubmissions);
    await this.getLatestRegionalPlans();

    // reload grid
    if (this.assignPlansToOneRegion) {
      this.onRegionChanged();
    } else {
      this.onPlanChanged();
    }
  }

  async assignPlansFromCSV(purpose: string, rows: PlanAssignmentCSVRecord[]): Promise<void> {
    let planSubmissions: PlanSubmission[];

    planSubmissions = rows.map((r) => {
      return {
        ServiceTreeId: r.ServiceTreeId,
        ServiceTeam: r.ServiceName,
        StartDate: r.NeedByDate,
        Version: 0,
        Stage: "GA",
        Region: this.selectedRegion.RegionName,
        Purpose: purpose,
      } as PlanSubmission;
    });

    await this.modalService.planAssignmentResponseModal(planSubmissions);
  }

  onSelectionChanged(params: GridOptions): void {
    var rows = params.api.getSelectedRows().length;
    this.hasRowSelected = rows > 0;
    this.assignTooManyRegions = rows > this.maxRegionNumOneTime;

    const groupedRows = new Map<string, Set<string>>();
    this.assignMultiStages = false;
    if (this.assignPlansToOneRegion) {
      if (this.selectedRegion.Status == RegionStatus.GA) {
        const selectedRows = this.gridApi.getSelectedRows() as RowDataForPlan[];
        selectedRows.forEach(row => {
          const key = row.ServiceTreeId;
          if (!groupedRows.has(key)) {
            groupedRows.set(key, new Set<string>());
          }
          else {
            this.assignMultiStages = true;
          }
        });
      }
    } else {
      const selectedRows = this.gridApi.getSelectedRows() as RowDataForRegion[];
      selectedRows.forEach(row => {
        if (row.RegionStatus != RegionStatus.GA) {
          return;
        }

        const key = row.RegionName;
        if (!groupedRows.has(key)) {
          groupedRows.set(key, new Set<string>());
        }
        else {
          this.assignMultiStages = true;
        }
      });
    }

    if (!this.assignPlansToOneRegion && !this.userProfile.IsAdmin && this.assignTooManyRegions ||
      !this.userProfile.IsAdmin && this.assignMultiStages
    ) {
      this.canAssign = false;
    }
    else {
      this.canAssign = true;
    }
  }

  onAssignModeChanged(_params: any): void {
    this.hasRowSelected = false;
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    setTimeout(() => {
      this.gridColumnApi.autoSizeAllColumns();
    }, 100);
  }

  onFilterChangedRegion(event: FilterChangedEvent): void {
    cacheFilterStatsLocal(event, this.keyFilterStatusOneRegion);
  }

  onFilterChangedPlan(event: FilterChangedEvent): void {
    cacheFilterStatsLocal(event, this.keyFilterStatusMultiRegions);
  }

  exportExcel() {
    var fileName = this.assignPlansToOneRegion
      ? `Assigned plans of region ${this.selectedRegion.RegionName}-` + getExportedExcelFileNameSuffix()
      : `Assigned regions of service ${this.selectedPlan.ServiceTeam}-` + getExportedExcelFileNameSuffix();
    var sheetName = this.assignPlansToOneRegion ? "Plans" : "Regions";

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }

  onFileSelected(event) {
    let file: File = event.target.files[0];

    if (this.validateFileName(file.name, "csv")) {
      let reader: FileReader = new FileReader();
      reader.readAsText(file);
      reader.onload = async () => {
        let planAssignments: PlanAssignmentCSVRecord[] = [];
        let csvContent: string = reader.result as string;
        let csvToRowArray = csvContent.split(/\r\n/g);
        for (let index = 1; index < csvToRowArray.length - 1; index++) {
          let row = csvToRowArray[index].split(",");
          planAssignments.push({
            ServiceTreeId: row[0].trim(),
            NeedByDate: new Date(row[1].trim()),
          } as PlanAssignmentCSVRecord);
        }

        await this.showPlanAssignmentCSVDialog(planAssignments, this.selectedRegion);
      };
    } else {
      this.notificationService.error("Please upload a csv file.");
    }

    // Clear the input
    event.target.value = null;
  }

  validateFileName(name: string, expected: string) {
    var actual = name.substring(name.lastIndexOf(".") + 1);
    if (actual.toLowerCase() == expected) {
      return true;
    } else {
      return false;
    }
  }

  async showPlanAssignmentCSVDialog(records: PlanAssignmentCSVRecord[], region: Region): Promise<void> {
    try {
      const result = await this.modalService.planAssignmentCSVModal(records, region);
      if (result?.purpose) {
        await this.assignPlansFromCSV(result.purpose, result.rows);
      }
    } catch {
      // For the model dialog dismiss
      return;
    }
  }
}
