import { Component, OnInit } from "@angular/core";
import { ColumnApi, ExcelStyle, GridApi, GridOptions } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "../../services";
import { LoadingScreenService } from "../loading-screen.service";
import { ServiceTeamUserColumnDefinition } from "./ag-grid-column-definition";
import { ModalService } from "src/app/shared/modal.service";
import { App } from "src/app/generated-models/App";
import { IsServiceAdmin } from "src/app/utility/userprofile-helper";
import { IUserProfile } from "src/app/npr-request.model";
import { GridViewBaseComponent } from "../../utility/grid-view-base.component";
import { SharedDataService } from "../../services/sharedDataService";
import { exportExcel, getExportedExcelFileNameSuffix } from "src/app/utility/common-helper";
import { AddAppModalOptions } from "../add-app-modal/add-app.component";
import { NewApp } from "src/app/generated-models/NewApp";

@Component({
  templateUrl: "./service-team-app-management.component.html",
  styleUrls: ["../../styles.scss", "./service-team-app-management.component.scss"],
})
export class ServiceTeamAppManagement extends GridViewBaseComponent implements OnInit {
  gridOptions: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;
  userProfile: IUserProfile;

  // Used in GridViewBaseComponent to store filter onto localStorage
  keyFilterStats = "filter:my-service-team-apps";

  rowData: App[] = [];
  selectedRow: App;
  selectedRowsCount = 0;
  isAddable = false;
  headerStyle: ExcelStyle[] = [
    {
      id: "header",
      font: {
        bold: true,
      },
    },
  ];

  message: string;

  adminServiceTreeIds: string[];

  constructor(
    protected apiService: ApiService,
    protected modalService: ModalService,
    private loadingService: LoadingScreenService,
    protected sharedDataService: SharedDataService,
    private notificationService: ToastrService
  ) {
    super(apiService, sharedDataService);
  }

  ngOnInit(): void {
    super.InitAgGrid(ServiceTeamUserColumnDefinition);
    this.gridOptions.rowSelection = "single";
    this.apiService.getMyServiceTeamServiceTreeIds().subscribe(
      (response) => {
        this.adminServiceTreeIds = response;
      },
      (err: unknown) => {
        this.adminServiceTreeIds = [];
        this.notificationService.error(err as string);
      }
    );

    this.apiService
      .getUserProfile()
      .then((response) => {
        if (response) {
          this.userProfile = response;
          this.isAddable = IsServiceAdmin(this.userProfile);
        } else {
          this.notificationService.error("user profile returns null, no data was loaded.");
        }
      })
      .catch((error) => {
        this.notificationService.error(`unable to get user profile, error: ${error.message}`);
      });
  }

  onSelectionChanged(event: GridOptions): void {
    const nodes = event.api.getSelectedNodes();
    if (nodes && nodes.length > 0) {
      this.selectedRowsCount = nodes.length;
      this.selectedRow = nodes[0].data;
    } else {
      this.selectedRowsCount = 0;
      this.selectedRow = null;
    }
  }

  refreshData(): void {
    this.loadingService.setLoading(true);

    this.apiService.getMyServiceTeamApp().subscribe(
      (response) => {
        this.rowData = response;
        this.loadingService.setLoading(false);
        setTimeout(() => {
          this.gridColumnApi.autoSizeAllColumns();
        }, 100);
        super.setupFilterByLocalData();
      },
      (e: unknown) => {
        this.rowData = [];
        this.loadingService.setLoading(false);
        this.notificationService.error(e as string);
      }
    );
  }

  async showAddingNewAppDialog() {
    const options: AddAppModalOptions = {
      save: this.addNewApp.bind(this),
      adminTeamList: this.adminServiceTreeIds,
    };
    await this.modalService.planAddAppModal(options);
  }

  isDeletable(): boolean {
    // Only one row could be selected at a time as specified in gridOptions.rowSelection = "single";
    return this.selectedRow && IsServiceAdmin(this.userProfile, this.selectedRow.ServiceTreeId);
  }

  addNewApp(app: NewApp): void {
    this.loadingService.setLoading(true);

    this.apiService.addMyServiceTeamApp(app).subscribe(
      () => {
        this.message = `New App ${app.AppId} have been added to ${app.ServiceTreeId} successfully.`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (error: unknown) => {
        this.notificationService.error(error as string);
        this.loadingService.setLoading(false);
      },
      () => {
        this.refreshData();
      }
    );
  }

  deleteApp(): void {
    if (!this.selectedRow) {
      this.message = "Please select one app to delete";
      this.notificationService.info(this.message);
      return;
    }

    this.apiService.deleteMyServiceTeamApp(this.selectedRow.ServiceTreeId, this.selectedRow.AppId).subscribe(
      () => {
        this.message = `App ${this.selectedRow.AppId} has been deleted from service ${this.selectedRow.ServiceName}`;
        this.notificationService.info(this.message);
        this.loadingService.setLoading(false);
      },
      (error: unknown) => {
        this.notificationService.info(error as string);
        this.loadingService.setLoading(false);
      },
      () => {
        this.refreshData();
      }
    );
  }

  exportExcel() {
    var fileName = `MyServiceTeamApps-${getExportedExcelFileNameSuffix()}`;
    var sheetName = "My Service Team Apps";

    this.loadingService.setLoading(true);
    exportExcel(this.gridApi, fileName, sheetName);
    this.loadingService.setLoading(false);
  }
}
