<div class="title" #title>
  <div class="container container-max-width">
    <div>
      <h4 *ngIf="planType; else elseBlock">Blueprint Editor - {{ serviceTeam }} - {{ planType }}</h4>
      <ng-template #elseBlock>
        <h4>Blueprint Editor - {{ serviceTeam }}</h4>
      </ng-template>
      <p>Author region agnostic plan(latest version is {{ latestVersion }})</p>
    </div>
    <div class="action-container">
      <button type="button" class="btn btn-danger" (click)="undoOnboarding()" *ngIf="canUndoOnboarding">Undo Onboarding</button> &nbsp;
      <button type="button" class="btn btn-primary" (click)="showPlanHistoryDialog()">History</button> &nbsp;
      <button
        type="button"
        class="btn btn-primary"
        (click)="showCommitPlanDialog()"
        [disabled]="isReadonly || !ifFilesChanged"
        data-test="Commit">
        Commit
      </button>
    </div>
  </div>
</div>
<div class="container container-content container-max-width" (window:resize)="onResize()" [style.height]="containerHeight">
  <div class="landing-main" style="display: flex">
    <div class="card mb-2 mt-4 mr-1 col-md-6" style="float: left; padding: 0">
      <div class="card-header flex-row">
        <div class="float-left">
          <span>Source plan files</span>
          <span class="font-weight-bold" *ngIf="ifFilesChanged">&nbsp;(Uncommitted changes exist)</span>
        </div>

        <div class="float-right" style="height: 24px">
          <button
            type="button"
            [disabled]="isReadonly"
            class="btn btn-link"
            style="height: 24px; padding: 0"
            (click)="showPlanNewFileDialog()">
            <i class="fas fa-plus" ngbTooltip="Add&nbsp;a&nbsp;new&nbsp;file"></i>
          </button>
        </div>
      </div>
      <div class="card-block p-3">
        <ag-grid-angular
          #agGrid
          id="ViewGrid"
          style="width: 100%; height: 540px"
          class="ag-theme-balham"
          [gridOptions]="gridOptions"
          [rowData]="rowSourceData"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
    <div class="card mb-2 mt-4 ml-1 col-md-6" style="float: right; padding: 0">
      <div class="card-header flex-row">
        <div class="float-left">
          <span>Effective plan files</span>
          <label
            class="ml-1"
            ngbTooltip="When plan assigned to a region, we will look for the region specific plan file first (e.g. westus3.json, chinanorth2.json). If not found, we will look for the cloud specific plan file (e.g. public.json, mooncake.json) or region type specific plan file (e.g. hero.json, hub.json). Finally, if none exists, we fallback to default.json."
            ><i class="fa fa-info-circle"></i
          ></label>
        </div>
      </div>
      <div class="card-block p-3">
        <ag-grid-angular
          #agGrid
          id="ViewGrid"
          style="width: 100%; height: 540px"
          class="ag-theme-balham"
          [gridOptions]="gridOptionsMerged"
          [rowData]="rowMergedData"
          (gridReady)="onGridReady($event)">
        </ag-grid-angular>
      </div>
    </div>
  </div>
</div>
