<div class="title container container-max-width btn-toolbar justify-content-between">
  <h4>Request Details</h4>
  <div class="btn-group">
    <button
      type="submit"
      data-test="ApproveButton"
      [disabled]="!this.userProfile?.IsApprover || !pendingApprovalSubrequests || pendingApprovalSubrequests.length == 0"
      class="btn btn-success mr-1"
      (click)="approveRequests()">
      Approve
    </button>
    <button *ngIf="isAdmin" type="submit" class="btn btn-primary mr-1" (click)="retry()">Retry</button>
    <button *ngIf="canSkipDenpendencies" type="submit" class="btn btn-primary mr-1" (click)="skipDependencies()">
      Skip Dependencies
    </button>
  </div>
</div>
<div class="container ags-forms-container container-content container-max-width">
  <div class="form-content" *ngIf="errorInfo">
    <fieldset class="fieldsetstyle">
      <h5 class="font-weight-bold">{{ errorInfo }}</h5>
    </fieldset>
  </div>
  <div class="form-content" *ngIf="request && !isLoading && !errorInfo">
    <form novalidate class="form-horizontal metadata-validator ng-untouched ng-pristine ng-valid">
      <!--section 1: basic info of the request-->
      <fieldset class="fieldsetstyle">
        <div class="form-group row mb-0">
          <div class="col-sm-6 popover-container">
            <h5 class="font-weight-bold">
              {{ requestId }} [<label [ngClass]="request.Status" data-test="RequestStatus">{{ request.Status }}</label>]
            </h5>
          </div>
        </div>

        <hr class="mt-0" />

        <div>
          <table class="content-table">
            <tbody>
              <tr class="content-table-row">
                <td class="col-form-label col-sm-3 content-table-data-key">Subscription Id</td>
                <td class="col-sm-6 form-control-plaintext content-table-data-value">
                  <a href="javascript:void(0)" class="topbar-item" (click)="getSubscriptionDetails()" container="body">
                    {{ request.SubscriptionId }}
                  </a>
                  <span *ngIf="request.IsExternal" class="ml-2">| External</span>
                  <span *ngIf="request.IsHobo" class="ml-2">| Hobo</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div>
          <table class="content-table">
            <tr class="content-table-row">
              <td class="col-form-label col-sm-3 content-table-data-key">Region Name</td>
              <td class="col-sm-6 form-control-plaintext content-table-data-value">
                <span>{{ request.Region }}</span>
                <span class="ml-2">| {{ request.RegionStatus }}</span>
              </td>
            </tr>
          </table>
        </div>

        <div>
          <table class="content-table">
            <tr class="content-table-row">
              <td class="col-form-label col-sm-3 content-table-data-key">Requestor</td>
              <td class="col-sm-6 form-control-plaintext content-table-data-value">
                <span>{{ request.Requestor }}</span>
              </td>
            </tr>
          </table>
        </div>

        <div>
          <table class="content-table">
            <tr class="content-table-row">
              <td class="col-form-label col-sm-3 content-table-data-key">Submitter</td>
              <td class="col-sm-6 form-control-plaintext content-table-data-value">
                {{ request.Submitter }}
              </td>
            </tr>
          </table>
        </div>

        <div>
          <table class="content-table">
            <tr class="content-table-row">
              <td class="col-form-label col-sm-3 content-table-data-key">Source</td>
              <td class="col-sm-6 form-control-plaintext content-table-data-value">
                {{ request.RequestSource }}
              </td>
            </tr>
          </table>
        </div>

        <div>
          <table class="content-table">
            <tr class="content-table-row">
              <td class="col-form-label col-sm-3 content-table-data-key">Contact Email</td>
              <td class="col-sm-6 form-control-plaintext content-table-data-value">
                {{ request.Email }}
              </td>
            </tr>
          </table>
        </div>

        <div *ngIf="request.TeamOid">
          <table class="content-table">
            <tr class="content-table-row">
              <td class="col-form-label col-sm-3 content-table-data-key">Team Oid</td>
              <td class="col-sm-6 form-control-plaintext content-table-data-value">
                {{ request.TeamOid }}
              </td>
            </tr>
          </table>
        </div>

        <div>
          <table class="content-table">
            <tr class="content-table-row">
              <td class="col-form-label col-sm-3 content-table-data-key">Created Time</td>
              <td class="col-sm-6 form-control-plaintext content-table-data-value">
                {{ request.CreatedTime }}
              </td>
            </tr>
          </table>
        </div>

        <div *ngIf="request.CompletedTime">
          <table class="content-table">
            <tr class="content-table-row">
              <td class="col-form-label col-sm-3 content-table-data-key">Completed Time</td>
              <td class="col-sm-6 form-control-plaintext content-table-data-value">
                {{ request.CompletedTime }}
              </td>
            </tr>
          </table>
        </div>

        <div *ngIf="request.Notes">
          <table class="content-table">
            <tr class="content-table-row">
              <td class="col-form-label col-sm-3 content-table-data-key">Notes</td>
              <td class="col-sm-6 form-control-plaintext content-table-data-value">
                {{ request.Notes }}
              </td>
            </tr>
          </table>
        </div>

        <div *ngIf="request.IsFlighting">
          <table class="content-table">
            <tr class="content-table-row">
              <td class="col-form-label col-sm-3 content-table-data-key">Is Flighting</td>
              <td class="col-sm-6 form-control-plaintext content-table-data-value">
                {{ request.IsFlighting }}
              </td>
            </tr>
          </table>
        </div>

        <div>
          <table class="content-table">
            <tr class="content-table-row">
              <td class="col-form-label col-sm-3 content-table-data-key">Justification</td>
              <td class="col-sm-6 form-control-plaintext content-table-data-value" [innerHtml]="request.Justification">
              </td>
            </tr>
          </table>
        </div>

        <div *ngIf=isRdquota>
          <table class="content-table">
            <tr class="content-table-row">
              <td class="col-form-label col-sm-3 content-table-data-key">Support Contact</td>
              <td class="col-sm-6 form-control-plaintext content-table-data-value">
                One or more sub requests are fulfilled via RDQuota ticket. Please use the links in the <b>Ticket</b> section of the sub request detail page to interact with capacity managers if necessary.
              </td>
            </tr>
          </table>
        </div>
      </fieldset>

      <!--section 2: subrequests of the request-->
      <fieldset class="fieldsetstyle">
        <div>
          <div>
            <h5 attr.data-test-status="{{ request.Status }}">Sub Requests</h5>
          </div>
        </div>
        <hr />

        <div class="nofloat">
          <ag-grid-angular
            #agGrid
            id="ApproverViewGrid"
            style="width: 100%; height: 540px"
            class="ag-theme-balham"
            [gridOptions]="gridOptions"
            [rowData]="rowData"
            pagination="true"
            rowSelection="multiple"
            (gridReady)="onGridReady($event)"
            (rowGroupOpened)="onRowGroupOpened($event)"
          >
          </ag-grid-angular>
        </div>
      </fieldset>

      <!--section 3: operation log of parent requests-->
      <fieldset class="fieldsetstyle" *ngIf="operationLogs && operationLogs.length > 0">
        <h5>History</h5>
        <hr />
        <ag-grid-angular
          class="ag-theme-balham"
          [gridOptions]="operationLogGridOptions"
          [rowData]="operationLogs"
          [masterDetail]="true"
          pagination="true"
          rowSelection="multiple"
          (gridReady)="onOperationLogGridReady($event)"
        >
        </ag-grid-angular>
      </fieldset>
    </form>
  </div>
</div>
