import * as CDs from "src/app/utility/commonColDef";
import {
  getRequestStatusDescription,
  GetSubRequestDetailsPageLink,
  GetSubRequestDetailsPageWithCommentLink,
  requestStatusMapping,
} from "../../utility/common-helper";
import { JsonCellRenderer, QuotaCellRenderer } from "../../utility/gridCellRenderer";
import { ModalService } from "../../shared/modal.service";

function statusModel(params) {
  const modalService = params.context.modalService as ModalService;
  const name = getRequestStatusDescription(params.value)?.name;
  // We have different Modal for pending approval
  if (name === "Pending Approval") {
    modalService.previousApprovalsModal(params.data.ParentRequestId, params.data.SubRequestId);
  } else {
    const descriptions = requestStatusMapping
      .filter((m) => m.description)
      .filter((value, index, self) => self.findIndex((d) => d.name === value.name) === index)
      .map((m) => {
        const current = m.name === name;
        return `${(current && "<mark>") || ""}<b>${m.name}:</b>${m.description}${(current && "</mark>") || ""}`;
      })
      .join("<br/><br />\n");
    modalService.promptModal("Status Description", descriptions, "xl");
  }
}

export const UserSubRequestItemsColumnDefinition = [
  {
    headerName: "ID",
    valueGetter: (params) => {
      return {
        id: params.data.RequestId,
        link: params.data.RequestLink,
      };
    },
    cellRenderer: (param) => {
      return param.value.link;
    },
    // the function to generate the key used by the filter.
    keyCreator: (param) => {
      return param.value.id;
    },
  },
  {
    ...CDs.Status,
    onCellClicked: statusModel,
    cellRenderer: (params) => {
      if (params.value === "ActionRequired") {
        return GetSubRequestDetailsPageWithCommentLink(params.data.RequestId, params.value, true, 'red');
      }
      let status = "";
      if (params.value === "InProgress" && params.data.FulfillChannel === "RDQuota") {
        status = "Awaiting RDQuota";
      } else {
        status = getRequestStatusDescription(params.value)?.name || params.value;
      }
      return `<a href="javascript:void(0)">${status}</a>`;
    },
  },
  CDs.RequestDisplayServiceType,
  CDs.SKU,
  CDs.Quota,
  CDs.PriorityString,
  CDs.CreatedTime,
  CDs.CompletedTime,
  { headerName: "Ticket ID", field: "TicketIds" },
  CDs.Notes,
  CDs.Dependencies,
];

export const UserParentRequestItemsColumnDefinition = [
  {
    headerName: "Parent Request Info",
    children: [
      {
        ...CDs.RequestId,
        checkboxSelection: false,
        headerCheckboxSelection: false,
        cellRenderer: "agGroupCellRenderer",
        cellRendererParams: {
          suppressCount: true, // turn off the row count
          suppressDoubleClickExpand: true, // turn off double click for expand
          innerRenderer: (params) => {
            // provide an inner renderer
            if (params.data.HasActionRequired) {
              return `${params.data.RequestLink}<i class="fas fa-exclamation text-danger ml-1"></i>`;
            } else if (params.data.HasBacklogged || params.data.HasForelogged) {
              return `${params.data.RequestLink}<i class="fas fa-exclamation text-primary ml-1"></i>`;
            } else {
              return params.data.RequestLink;
            }
          },
        },
        tooltipField: "RequestId",
        tooltipComponent: "statusTooltip",
      },
      CDs.Requestor,
      CDs.Submitter,
      CDs.RegionColDef("Region"),
      CDs.SubscriptionId("SubscriptionId", { cellRenderer: "subscriptionRenderer" }),
      CDs.IsExternalDisplay,
      CDs.IsHoboDisplay,
      CDs.Status,
      CDs.CreatedTime,
      { ...CDs.CompletedTime, hide: true },
      CDs.Email,
      { headerName: "Enable", field: "Details.Enable" },
      { headerName: "VMSKU", field: "Details.VmSkus" },
      {
        headerName: "VMQuota",
        field: "Details.VmQuota",
        cellRenderer: (params) => JsonCellRenderer(params),
      },
      { headerName: "RDFE VM Quota", field: "Details.RdfeVmQuota", filter: 'agNumberColumnFilter' },
      {
        headerName: "Storage Account Quota",
        field: "Details.StorageAccountQuota",
        cellRenderer: (params) => JsonCellRenderer(params),
      },
      {
        headerName: "SQL Quota",
        field: "Details.SqlQuota",
        cellRenderer: (params) => JsonCellRenderer(params),
      },
      {
        headerName: "SQL DTU Quota",
        field: "Details.SqlDtuQuota",
        cellRenderer: (params) => JsonCellRenderer(params),
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: "HDI Quota",
        field: "Details.HdiQuota",
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: "Virtual Machine Disks Quota",
        field: "Details.VmDisksQuota",
        cellRenderer: (params) => JsonCellRenderer(params),
        filter: 'agNumberColumnFilter',
      },
      {
        headerName: "Batch Quota",
        field: "Details.BatchQuota",
        cellRenderer: (params) => JsonCellRenderer(params),
      },
      {
        headerName: "DNSEntry Quota",
        field: "Details.DnsEntryQuota",
        cellRenderer: (params) => JsonCellRenderer(params),
      },
      {
        headerName: "CosmosDB Quota",
        field: "Details.CosmosDbQuota",
        cellRenderer: (params) => JsonCellRenderer(params),
      },
      {
        headerName: "Kusto Quota",
        field: "Details.KustoQuota",
        cellRenderer: (params) => JsonCellRenderer(params),
      },
      {
        headerName: "AppService Quota",
        field: "Details.AppServiceQuotas",
        cellRenderer: (params) => JsonCellRenderer(params),
      },
      { headerName: "ARM Resource Type", field: "Details.ArmResourceTypes" },
      {
        ...CDs.Justification,
        tooltipField: "Justification",
        maxWidth: 500,
      },
      CDs.Notes,
    ],
  },
];

export const CustomerRequestSubsColumnDefinition = [
  CDs.BillingAccountId,
  CDs.RegionColDef("Region"),
  CDs.SubscriptionId("SubscriptionId"),
  CDs.CompletedTime,
];
