import { Component, Input, OnInit } from "@angular/core";
import { NgModel } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { NprConfiguration } from "src/app/app.configuration";
import { NewApp } from "src/app/generated-models/NewApp";
import { ITeamListRecord } from "src/app/npr-request.model";
import { ApiService } from "src/app/services";
import { LoadingScreenService } from "src/app/shared/loading-screen.service";
import { isNonEmptyString } from "src/app/utility/common-helper";

export interface AddAppModalOptions {
  save: (app: NewApp) => Promise<any>;
  adminTeamList: string[];
}

@Component({
  templateUrl: "./add-app.component.html",
  styleUrls: ["../../styles.scss"],
})
export class AddAppComponent implements OnInit {
  @Input() options: AddAppModalOptions;
  guidRegexp = new RegExp(NprConfiguration.guidRegExpression);
  team: ITeamListRecord;
  teamList: ITeamListRecord[];
  appId: string;

  constructor(
    public activeModal: NgbActiveModal,
    private apiService: ApiService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService
  ) {
    this.teamList = [];
  }

  async ngOnInit() {
    this.loadingService.setLoading(true);

    // Team List show in the drop down
    this.apiService?.getTeamList().subscribe(
      (response) => {
        const regionlessTeam = [];
        response
          .filter((team) => this.options.adminTeamList.includes(team.Oid))
          .map((r) => {
            if (r.Ring) {
              this.teamList.push({
                label: `${r.Name} (${r.Oid})`,
                value: 0,
                ring: r.Ring,
                oid: r.Oid,
              } as ITeamListRecord);
            } else {
              regionlessTeam.push({
                label: `${r.Name} (${r.Oid})`,
                value: 0,
                ring: "No Ring",
                oid: r.Oid,
              } as ITeamListRecord);
            }
          });
        this.teamList = this.teamList
          .sort((a, b) => {
            if (a.label === null) {
              console.log(a);
            }
            return a.ring.localeCompare(b.ring) || a.label.localeCompare(b.label);
          })
          .concat(regionlessTeam.sort((a, b) => a.label.localeCompare(b.label)));
        this.loadingService.setLoading(false);
      },
      (e: any) => {
        this.loadingService.setLoading(false);
        this.notificationService.error(e);
      }
    );
  }

  async submit() {
    if (!this.team) {
      this.notificationService.warning(`Please select a service name.`);
      return;
    } else {
      try {
        const newApp: NewApp = {
          ServiceTreeId: this.team.oid,
          AppId: this.appId,
        };
        const result = await this.options.save(newApp);
        this.activeModal.close(result);
      } catch (e) {
        this.notificationService.error(e);
      }
    }
  }

  isTouchedOrDirty(control: NgModel) {
    return control && (control.touched || control.dirty);
  }

  get isAppIdValid() {
    return isNonEmptyString(this.appId) && this.guidRegexp.test(this.appId);
  }
}
