import { Component, HostListener, OnInit } from "@angular/core";
import { ColDef, ColumnApi, FilterChangedEvent, GridApi, GridOptions, RowNode } from "ag-grid-community";
import { ToastrService } from "ngx-toastr";
import { ColParams } from "src/app/shared/grid/ag-grid";
import { PlanRegion } from "src/app/generated-models/PlanRegion";
import { ModalService } from "src/app/shared/modal.service";
import { PlanSubmission } from "src/app/generated-models/PlanSubmission";
import * as CDs from "src/app/utility/commonColDef";
import { extendDefaultOptions, setupFilterByLocalData, cacheFilterStatsLocal } from "../../utility/gridHelper";
import { BaseComponent } from "../../shared/base.component";
import { LoadingScreenService } from "../../shared/loading-screen.service";
import { ApiService } from "../../services";
import { RegionStatusEnum } from "src/app/shared/enums/plan-enums";
import { Region } from "src/app/generated-models";
import { IUserProfile, PlanListRecord, RegionStatus, allowedCommonRegionStatuses } from "src/app/npr-request.model";

interface RowDataForRegion {
  RegionName: string;
  RegionStatus: string;
  Stage: string;
  AssignedVersion?: number;
  AlreadyAssigned?: string;
  AssignedBy?: string;
  AssignedTime?: Date | null;
  ApprovedBy?: string;
  ApprovedTime?: Date | null;
  ValidTo?: string;
  ValidToDate?: Date | null;
  Purpose?: string;
  RegionalPlanStatus?: string;
  IsCappedForCapacityOrder: boolean;
}

@Component({
  templateUrl: "./plans-assignment-service-owner.component.html",
  styleUrls: ["../../styles.scss", "./plan.scss"],
})
export class PlansAssignmentServiceOwnerComponent extends BaseComponent implements OnInit {
  regions: Region[] = [];
  selectedRegion: Region;
  regionEnum = RegionStatusEnum;
  userProfile: IUserProfile = null;

  rowDataForRegion: RowDataForRegion[] = [];

  gridOptionsForRegion: GridOptions;
  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  initPageSize = 15;

  plans: PlanListRecord[] = [];
  selectedPlan: PlanListRecord;

  planRegions: PlanRegion[] = [];

  hideAlreadyAssigned = false;
  hasRowSelected = false;

  keyFilterStatusMultiRegions = "filter:one-to-multiple";
  maxRegionNumOneTime = 3;
  canAssign = false;
  assignTooManyRegions = false;
  assignMultiStages = false;

  constructor(
    private apiService: ApiService,
    private modalService: ModalService,
    private loadingService: LoadingScreenService,
    private notificationService: ToastrService
  ) {
    super();
  }

  async ngOnInit() {
    this.hasRowSelected = false;
    this.canAssign = false;

    const RegionsColumnDefinition: ColDef[] = [
      {
        colId: "customFilter",
        valueGetter: (params: ColParams<RowDataForRegion, void>) => {
          if (this.hideAlreadyAssigned) {
            return this.isAssignable(params.data);
          }

          return true;
        },
        filterParams: {
          values: ["true", "false"],
          newRowsAction: "keep",
        },
        hide: true,
      },
      {
        ...CDs.RegionColDef("RegionName"),
        checkboxSelection: true,
        headerCheckboxSelection: true,
      },
      CDs.Stage,
      CDs.AlreadyAssigned,
      CDs.AssignedVersion,
      CDs.AssignedBy,
      CDs.AssignedTime,
      CDs.RegionalPlanStatus,
      CDs.ApprovedBy,
      CDs.ApprovedTime,
      CDs.Purpose,
      CDs.ValidTo,
      CDs.IsCappedForCapacityOrder,
    ];

    this.gridOptionsForRegion = extendDefaultOptions({
      columnDefs: RegionsColumnDefinition,
      suppressPaginationPanel: true,
      isRowSelectable: (node: RowNode) => this.isAssignable(node.data),
      enableRangeSelection: false,
      rowSelection: "multiple",
      animateRows: true,
      statusBar: {
        statusPanels: [
          { statusPanel: 'agTotalAndFilteredRowCountComponent' },
          { statusPanel: 'agTotalRowCountComponent' },
          { statusPanel: 'agFilteredRowCountComponent' },
          { statusPanel: 'agSelectedRowCountComponent' },
          { statusPanel: 'agAggregationComponent' }
        ]
      }
    });

    this.apiService.getUserProfile().then(async (response) => {
      this.userProfile = response;
      if (response) {
        this.gridApi?.showLoadingOverlay();

        // Region List
        // includeDisabled = true, includeAirGapped = false
        const regionListResponse = await this.apiService.getRegionList(false, false).toPromise();
        if (!regionListResponse) {
          console.warn("No region obtained from server.");
        } else {
          this.regions = regionListResponse
            .filter(region => allowedCommonRegionStatuses.includes(region.Status))
            .sort((region1, region2) => {
              return this.regionEnum[region1.Status.replace(/\s/g, "")] - this.regionEnum[region2.Status.replace(/\s/g, "")];
            });
        }
      }
    });

    this.loadingService.setLoading(true);

    // Plan List
    const planListResponse = await this.apiService.getMyPlans().toPromise();
    if (!planListResponse) {
      console.warn("No plan obtained from server.");
    } else {
      this.plans = planListResponse
        .filter((p) => p.Version > 0)
        .map((p) => ({ ...p, Label: `${p.ServiceTeam} (${p.ServiceTreeId})` } as PlanListRecord));
    }

    await this.getLatestRegionalPlans();

    this.loadingService.setLoading(false);
  }

  onGridReady(params: GridOptions): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.setFilterModel({
      customFilter: ["true"],
    });
  }

  async getLatestRegionalPlans(): Promise<void> {
    return this.apiService
      .getLatestRegionalPlans()
      .toPromise()
      .then(
        (response) => {
          this.planRegions = response;
          setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStatusMultiRegions);
        },
        (e: any) => {
          this.planRegions = [];
          this.notificationService.error(e);
        }
      );
  }

  onPlanChanged(): void {
    this.rowDataForRegion = [];

    if (this.selectedPlan) {
      const rowData: RowDataForRegion[] = [];

      const stages = this.selectedPlan.Stages.split(",");
      this.regions.forEach((region) => {
        stages.forEach((stage) => {
          const row: RowDataForRegion = {
            RegionName: region.RegionName,
            RegionStatus: region.Status,
            Stage: stage,
            IsCappedForCapacityOrder: region.IsCappedForCapacityOrder,
            Purpose: this.selectedPlan.Purpose,
          };

          rowData.push(row);
        });
      });

      rowData.forEach((row) => {
        row.AlreadyAssigned = "No";

        const planRegion = this.planRegions.find(
          (p) => p.Region === row.RegionName && p.ServiceTreeId === this.selectedPlan.ServiceTreeId && p.Stage === row.Stage
        );
        if (planRegion) {
          if (planRegion.Version === this.selectedPlan.Version) {
            row.AlreadyAssigned = "Yes";
          }

          row.AssignedVersion = planRegion.Version;
          row.AssignedBy = planRegion.Submitter;
          row.AssignedTime = planRegion.SubmitTime;
          row.ApprovedBy = planRegion.ApprovedBy;
          row.ApprovedTime = planRegion.ApprovedTime;
          row.ValidTo = planRegion.ValidTo;
          row.ValidToDate = planRegion.ValidToDate;
          row.Purpose = planRegion.Purpose;
          row.RegionalPlanStatus = planRegion.Status;
        }
      });
      this.rowDataForRegion = rowData;
      this.hasRowSelected = false;
      this.canAssign = false;
      setTimeout(() => {
        this.gridColumnApi.autoSizeAllColumns();
      }, 100);
    }
    setupFilterByLocalData(this.gridApi, this.gridColumnApi, this.keyFilterStatusMultiRegions);
  }

  // Plan is not able to be assigned if the latest version is already assigned to the region
  isAssignable(row: RowDataForRegion): boolean {
    return row.AlreadyAssigned === "No";
  }

  onHideAlreadyAssignedChanged(): void {
    this.gridApi.onFilterChanged();
  }

  async showPlanAssignmentDialog(): Promise<void> {
    try {
      var planPurpose = this.selectedPlan?.Purpose;
      const result = await this.modalService.planAssignmentModal(planPurpose);
      if (result?.purpose) {
        await this.assignPlans(result.purpose, result.startDate);
      }
    } catch {
      // For the model dialog dimiss
      return;
    }
  }

  async assignPlans(purpose: string, startDate: Date): Promise<void> {
    const selectedRows = this.gridApi.getSelectedRows() as RowDataForRegion[];
    const planSubmissions = selectedRows.map((r) => {
      return {
        ServiceTreeId: this.selectedPlan.ServiceTreeId,
        ServiceTeam: this.selectedPlan.ServiceTeam,
        Version: this.selectedPlan.Version,
        Stage: r.Stage,
        Region: r.RegionName,
        Purpose: purpose,
        StartDate: startDate,
      } as PlanSubmission;
    });

    await this.modalService.planAssignmentResponseModal(planSubmissions);
    await this.getLatestRegionalPlans();

    // reload grid
    this.onPlanChanged();
  }

  onSelectionChanged(params: GridOptions): void {
    var rows = params.api.getSelectedRows().length;
    this.hasRowSelected = rows > 0;
    this.assignTooManyRegions = rows > this.maxRegionNumOneTime;
    const groupedRows = new Map<string, Set<string>>();
    this.assignMultiStages = false;

    const selectedRows = this.gridApi.getSelectedRows() as RowDataForRegion[];
    selectedRows.forEach(row => {
      if (row.RegionStatus != RegionStatus.GA) {
        return;
      }

      const key = row.RegionName;
      if (!groupedRows.has(key)) {
        groupedRows.set(key, new Set<string>());
      }
      else {
        this.assignMultiStages = true;
      }
    });

    if (!this.userProfile.IsAdmin && (this.assignTooManyRegions || this.assignMultiStages)) {
      this.canAssign = false;
    }
    else {
      this.canAssign = true;
    }
  }

  @HostListener("window:resize", ["$event"])
  onResize(): void {
    setTimeout(() => {
      this.gridColumnApi.autoSizeAllColumns();
    }, 100);
  }

  onFilterChangedPlan(event: FilterChangedEvent): void {
    cacheFilterStatsLocal(event, this.keyFilterStatusMultiRegions);
  }
}
